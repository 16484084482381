export const stateChoices = {
  VIC: "VIC",
  NSW: "NSW",
  QLD: "QLD",
  ACT: "ACT",
  NT: "NT",
  WA: "WA",
  SA: "SA",
  TAS: "TAS",
  OT: "Other",
  ND: "南岛",
  BD: "北岛",
}

export const enstateChoices = {
  VIC: "VIC",
  NSW: "NSW",
  QLD: "QLD",
  ACT: "ACT",
  NT: "NT",
  WA: "WA",
  SA: "SA",
  TAS: "TAS",
  OT: "Other",
  ND: "South island",
  BD: "North island",
}

export const auStateChoices = {
  VIC: "VIC",
  NSW: "NSW",
  QLD: "QLD",
  ACT: "ACT",
  NT: "NT",
  WA: "WA",
  SA: "SA",
  TAS: "TAS",
  OT: "Other",
}

export const nzStateChoices = {
  OT: "Other",
  ND: "南岛",
  BD: "北岛",
}

export const countryCode = {
  AU: "澳洲",
  NZ: "新西兰",
}

export const encountryCode = {
  AU: "Australia",
  NZ: "New Zealand",
}

export const qiuCode = {
  q: "求购",
  c: "出售",
}

export const enqiuCode = {
  q: "Want",
  c: "Sell",
}

export const payModes = {
  h: "按小时",
  c: "计件",
}

export const enpayModes = {
  h: "Hourly",
  c: "Piece wage",
}

export const marketCates = {
  ZS: "住宿",
  ES: "二手",
  TH: "车辆",
  ZT: "组团求伴",
  TW: "提问",
  XL: "闲聊",
  OT: "其他",
}

export const enmarketCates = {
  ZS: "Accommodation",
  ES: "Second hand",
  TH: "Cars",
  ZT: "Looking for group",
  TW: "Questions",
  XL: "Chat",
  OT: "Other",
}

export const workCates = {
  PA: "农牧业",
  FP: "渔业",
  TF: "伐木业",
  TH: "旅游服务业",
  OT: "其他",
}

export const enworkCates = {
  PA: "Plant and animal cultivation",
  FP: "Fishing and pearling",
  TF: "Tree farming and felling",
  TH: "Tourism and hospitality",
  OT: "Other",
}

export const FarmLogoNameList = [
  "default",
  "Horse",
  "Cattle",
  "Lettuce",
  "Taro",
  "Potato",
  "Blueberries",
  "Tomato",
  "Pears",
  "Kiwi",
  "Plum",
  "Coffee",
  "Eggplant",
  "Parsley",
  "Fish",
  "Hops",
  "Banana",
  "felling",
  "Mushroom",
  "Pistachio",
  "Spinach",
  "Radish",
  "Garlic",
  "Cabbage",
  "Carrot",
  "Mango",
  "Pineapple",
  "Strawberry",
  "Construction",
  "Broccoli",
  "Onion",
  "Chicken",
  "Grapes",
  "Shrimp",
  "Repairing",
  "Cherries",
  "Lime",
  "Ginger",
  "Pig",
  "Lemon",
  "Pepper",
  "Almonds",
  "Sheep",
  "Pear",
  "Watermelon",
  "Fig",
  "Flowers",
  "Green apple",
  "Orange",
  "Peach",
  "Cucumber",
  "Corn",
  "Green bean",
  "Papaya",
  "Milking",
  "Pumpkin",
  "Apples",
  "Litchi",
  "iwhver"
]

export const FarmLogoNameDictList = FarmLogoNameList.map((farm) => {
  return { name: farm, url: `/points/${farm.split(" ").join("")}.png` }
})

export const AUWORKOPENID = "o9cGu4joy7-F8UmJxVGv-KSXLRH8"

export const appEndpoint =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://app.iwhver.com"

export const backendEndpoint =
  process.env.NODE_ENV === "development" ? "" : "https://api.iwhver.com"

export const defaultGPS = {
  lng: 161.454384,
  lat: -36.552401,
}
