import React from "react"
import { connect } from "react-redux"

import {loginRequest, loginOpenid} from "./actions"
import LoginView from "./LoginView"

function Login(props) {
  const {
    login: { requesting, successful, messages, errors }, location
  } = props

  return (
    <LoginView
      messages={messages}
      requesting={requesting}
      successful={successful}
      errors={errors}
      loginRequest={props.loginRequest}
      loginOpenid={props.loginOpenid}
      location={location}
    />
  )
}

// Grab only the piece of state we need
const mapStateToProps = state => ({
  login: state.auth.login
})

// make Redux state piece of `login` and our action `loginRequest`
// available in this.props within our component
export default connect(
  mapStateToProps,
  { loginRequest, loginOpenid }
)(Login)
