import React, { useEffect, useRef, useState } from "react"
import mapboxgl from "mapbox-gl"
import {
  Satellite,
  Hotel,
  NaturePeople,
  Whatshot,
  EventAvailable,
  FilterList,
  AddLocation,
  ExpandLess,
} from "@material-ui/icons"
import {
  IconButton,
  Tooltip,
  Slider,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { FarmIcon } from "assets/icons"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import circleLogo from "assets/images/circle.png"
import eventLogo from "assets/images/event.png"
import workLogo from "assets/images/work.png"
import marketLogo from "assets/images/market.png"
import ItemsList from "./Items"
import moment from "moment"
import { endpoints } from "lib/end-points"
import { backendEndpoint, FarmLogoNameDictList } from "lib/constants"
import * as d3 from "d3"
import queryString from "query-string"
import { isMobile } from "react-device-detect"
import { useIntl } from "react-intl"

const imageList = [
  { name: "circle", url: circleLogo },
  { name: "market", url: marketLogo },
  { name: "event", url: eventLogo },
  { name: "work", url: workLogo },
]

const imagelist = imageList.concat(FarmLogoNameDictList)
const IwhverSlider = withStyles({
  root: {
    color: "#52af77",
    height: 4,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    height: 4,
    borderRadius: 2,
  },
})(Slider)

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    marginLeft: theme.spacing(1),
    borderRadius: 3,
    display: "inline-flex",
  },
  firstLineButton: {
    position: "absolute",
    zIndex: 1,
    width: 330,
    backgroundColor: "white",
    display: "inline-flex",
    borderRadius: 3,
  },
  addLocation: {
    position: "absolute",
    marginTop: 10,
    right: 300,
    zIndex: 1,
    width: "auto",
    display: "inline-flex",
  },
  addLocationLeft: {
    position: "absolute",
    marginTop: 10,
    right: 650,
    zIndex: 1,
    width: "auto",
    display: "inline-flex",
  },
  addLocationMobile: {
    position: "absolute",
    bottom: 120,
    left: 5,
    width: "auto",
    zIndex: 1,
    display: "inline-flex",
  },
  specialNorth: {
    position: "absolute",
    zIndex: 999,
  },
  map: {
    width: "100vw",
    height: "calc(100vh - 65px)",
    position: "absolute",
  },
  mapItems: {
    width: "calc(100vw - 350px)",
    height: "calc(100vh - 65px)",
    position: "absolute",
  },
  sliders: {
    position: "absolute",
    zIndex: 1,
    width: 330,
    top: 110,
    display: "list-item",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 20,
    borderRadius: 5,
    backgroundColor: "#eee9f5",
  },
  slider: {
    color: theme.palette.primary.main,
  },
  colorSecondary: {
    color: "grey !important",
  },
  scrollTop: {
    position: "absolute",
    bottom: 120,
    right: 5,
    width: "auto",
    zIndex: 1,
    display: "inline-flex",
  },
}))

const MapboxGLMap = (props) => {
  const location = props?.location
  const history = props?.history
  const pathname = location?.pathname
  const { formatMessage: f } = useIntl()
  // check whether have the param from share, if yes, show diago
  const params = queryString.parse(location?.search)
  const { cate, id: item_id } = params || {}

  const [defaultGPS, setDefaultGPS] = useState({
    lng: 145.464622,
    lat: -29.143806,
  })
  const defaultStartTimeUnix = moment().subtract(12, "months").unix()

  const mapContainer = useRef(null)
  const [map, setMap] = useState(null) // map
  const [satellite, setSatellite] = useState(false) // 卫星视图
  const [specialNorth, setSpecialNorth] = useState(false) // 北部二签视图
  const [specialA, setSpecialA] = useState(false) // 南部二签视图
  const [specialB, setSpecialB] = useState(false) // 森林火灾地区视图
  const [harvest, setHarvest] = useState(false) // 收获地图
  const [farm, setFarm] = useState(true) // 显示农场列表
  const [selectedPoint, setSelectPoint] = useState({ lat: 0, lng: 0 }) // 选点
  const [selectedFeatures, setFeatures] = useState([]) // 选择mixedlayer的信息
  const [refreshLatest, setRefreshLatest] = useState(false) //是否需要重新获取最新数据

  const [startTime, setStartTime] = useState({
    event_start_time: defaultStartTimeUnix,
    work_start_time: defaultStartTimeUnix,
    market_start_time: defaultStartTimeUnix,
    circle_start_time: defaultStartTimeUnix,
  }) // 从后台拉取数据的时间
  const [showMixedLayer, setShowMixedLayer] = useState(false)
  const [showRecent, setShowRecent] = useState("1") //是展示最近的后台数据（一周），还是展示slider，且展示所有历史数据
  const defaultMaxSlider =
    parseInt(
      moment().add(12, "months").diff(moment("2018-12-31"), "months", true)
    ) + 1

  const [recentFilter, setRecentFilter] = useState({
    event: defaultMaxSlider - 12, // from today
    work: defaultMaxSlider - 12,
    circle: defaultMaxSlider - 12,
  })

  const marks = [
    {
      value: defaultMaxSlider - 12,
      label: f({ id: "thismonth" }),
    },
  ]

  /* functions
   */

  const valuetext = (value) => {
    if (value % 12 === 0) {
      return `${18 + Math.floor(value / 12)}/${12}`
    } else if (value % 12 < 10) {
      return `${18 + Math.floor(value / 12) + 1}/0${value % 12}`
    } else {
      return `${18 + Math.floor(value / 12) + 1}/${value % 12}`
    }
  }

  const valueSlider = (key) => (e, value) => {
    setRecentFilter({ ...recentFilter, [key]: value })
  }

  const setSelectPointClick = () => {
    if (selectedPoint.lat === 0 && selectedPoint.lng === 0) {
      setSelectPoint({ lat: defaultGPS.lat, lng: defaultGPS.lng })
    } else {
      setSelectPoint({ lat: 0, lng: 0 })
    }
  }

  const flyTo = (gps) => {
    if (map) {
      map.flyTo({
        center: [gps.lng, gps.lat],
        zoom: 8,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      })
    }
  }

  // get cloud s3 geocode information, the start time for history information
  useEffect(() => {
    const getTime = async () => {
      const result = await fetch(
        "https://s3.auwhver.com/geocode/time.json" // TODO: change address
      )
      return result.json()
    }

    setStartTime(getTime())
  }, [setStartTime])

  const classes = useStyles()

  // effect to load the map and set it into state
  useEffect(() => {
    let ignore = false
    const handlePointSelected = (e) => {
      setSelectPoint({
        lng: e.result.geometry.coordinates[0],
        lat: e.result.geometry.coordinates[1],
      })
    }

    // 初始化地图
    const initializeMap = ({ setMap, mapContainer }) => {
      // put style for map
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/pascalsun/ck8r5huob0fze1iryyzegu9wx", // stylesheet location
        center: [defaultGPS.lng, defaultGPS.lat],
        zoom: 4,
        attributionControl: false,
      })

      if (!isMobile) {
        map.addControl(new mapboxgl.NavigationControl(), "bottom-right")
      }

      // put geocoder here
      const geocoder = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAPBOX_KEY,
        marker: false,
        countries: "au,nz",
        mapboxgl: mapboxgl,
      })
      geocoder.on("result", handlePointSelected)
      if (!isMobile) {
        map.addControl(geocoder, "top-right")
      }
      if (!isMobile) {
        map.addControl(
          new mapboxgl.GeolocateControl(
            {
              positionOptions: {
                enableHighAccuracy: true,
              },
              fitBoundsOptions: {
                maxZoom: 9,
              },
              trackUserLocation: true,
            },
            "top-right"
          )
        )
      }

      map.on("load", () => {
        if (!ignore) setMap(map)
        // load the list of image, to work as marker image
        // add layer farm list
        map.addLayer({
          id: "farms",
          type: "symbol",
          source: {
            type: "vector",
            url: "mapbox://pascalsun.cka9l9o2u06cm2amv5pxbvowm-6cq68",
          },
          "source-layer": "farm-2020-05-16",
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "logo"]],
              ["image", "default"],
            ],
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              // zoom is 5 (or less) -> circle radius will be 1px
              5,
              0.08,
              // zoom is 10 (or greater) -> circle radius will be 5px
              10,
              0.16,
            ],
            "icon-allow-overlap": true,
          },
        })
      })
    }

    if (!map) initializeMap({ setMap, mapContainer })
    return () => {
      ignore = true
    }
  }, [map, defaultGPS])

  // select the marker and post something
  const [marker, setMarker] = useState(null)

  useEffect(() => {
    if (!marker) {
      const theMarker = new mapboxgl.Marker({
        draggable: true,
      })
      setMarker(theMarker)
    }
    if (map && marker) {
      marker.setLngLat([selectedPoint.lng, selectedPoint.lat]).addTo(map)
      const handleMarkerChange = (e) => {
        const latLng = e.result
          ? {
              lng: e.result.geometry.coordinates[0],
              lat: e.result.geometry.coordinates[1],
            }
          : marker.getLngLat()
        setSelectPoint(latLng)
        marker.setLngLat([latLng.lng, latLng.lat])
      }
      marker.on("dragend", handleMarkerChange)
    }
  }, [map, marker, setMarker, selectedPoint, setSelectPoint])

  // help user identity where they are
  useEffect(() => {
    if (map) {
      navigator.geolocation.getCurrentPosition((position) => {
        setDefaultGPS({
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        })
        map.flyTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 8,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        })
      })
    }
  }, [map, setDefaultGPS])

  useEffect(() => {
    const param = `event_start_time=${startTime.event_start_time}&work_start_time=${startTime.work_start_time}&circle_start_time=${startTime.circle_start_time}&market_start_time=${startTime.market_start_time}`
    if (refreshLatest) {
      d3.json(`${backendEndpoint}${endpoints.latest_geojson}?${param}`).then(
        (data) => {
          map.getSource("latestMixedLayerSource").setData(data)
          setRefreshLatest(false)
        }
      )
    }
  }, [map, refreshLatest, setRefreshLatest, startTime])

  // load all images
  useEffect(() => {
    if (map) {
      imagelist.map((image) =>
        map.loadImage(image.url, (error, img) => {
          if (error) console.log(error)
          if (!error) {
            map.addImage(image.name, img)
          }
        })
      )
    }
  }, [map])

  useEffect(() => {
    if (map) {
      map.resize()
      // set satellite to show or not
      const satelliteLayer = map.getLayer("raster-satellite-streets")
      if (!satelliteLayer) {
        map.addLayer(
          {
            id: "raster-satellite-streets",
            source: {
              type: "raster",
              tiles: [
                `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v10/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_KEY}`,
              ],
              tileSize: 512,
            },
            type: "raster",
            layout: { visibility: "visible" },
          },
          "land-structure-line"
        )
      }

      // set harvest map, which click and hover will show detail
      const harvestLayer = map.getLayer("harvest")
      if (harvestLayer) {
        const popup = new mapboxgl.Popup()
        popup.setMaxWidth(650)
        map.on("click", "harvest", (e) => {
          popup
            .setLngLat(e.lngLat)
            .setHTML(e.features[0].properties.desc)
            .addTo(map)
        })
      }

      // set farm map which allow to show markers, and click to see info
      const farms = map.getLayer("farms")
      if (farms) {
        const popup = new mapboxgl.Popup()
        map.on("click", "farms", (e) => {
          popup
            .setLngLat(e.lngLat)
            .setHTML(e.features[0].properties.desc)
            .addTo(map)
        })
      }
    }
  }, [satellite, map])

  // backend data layer
  useEffect(() => {
    if (map) {
      // if mixed layer
      const mixedLayer = map.getLayer("mixedLayer")
      if (!mixedLayer) {
        map.addLayer({
          id: "mixedLayer",
          type: "symbol",
          source: {
            type: "geojson",
            data: "https://s3.auwhver.com/geocode/geocode.json",
          },
          layout: {
            "icon-image": ["get", "type"],
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              // zoom is 5 (or less) -> circle radius will be 1px
              5,
              0.08,
              // zoom is 10 (or greater) -> circle radius will be 5px
              10,
              0.1,
            ],
            "icon-allow-overlap": true,
          },
        })
      }
      const latestMixedLayer = map.getLayer("latestMixedLayer")
      if (!latestMixedLayer) {
        const param = `event_start_time=${startTime.event_start_time}&work_start_time=${startTime.work_start_time}&circle_start_time=${startTime.circle_start_time}&market_start_time=${startTime.market_start_time}`
        map.addSource("latestMixedLayerSource", {
          type: "geojson",
          data: `${backendEndpoint}${endpoints.latest_geojson}?${param}`,
        })
        map.addLayer({
          id: "latestMixedLayer",
          type: "symbol",
          source: "latestMixedLayerSource",
          layout: {
            "icon-image": ["get", "type"],
            "icon-size": [
              "interpolate",
              ["linear"],
              ["zoom"],
              // zoom is 5 (or less) -> circle radius will be 1px
              5,
              0.08,
              // zoom is 10 (or greater) -> circle radius will be 5px
              10,
              0.1,
            ],
            "icon-allow-overlap": true,
          },
        })
      }
      map.on("click", (e) => {
        const width = 15
        const height = 15
        const point = e.point
        const features = map.queryRenderedFeatures(
          [
            [point.x - width / 2, point.y - height / 2],
            [point.x + width / 2, point.y + height / 2],
          ],
          { layers: ["latestMixedLayer", "mixedLayer"] }
        ) // 从多个图层里面取值
        setFeatures(features)
      })
    }
  }, [map, startTime, setFeatures])

  useEffect(() => {
    const convertMonth = (value) => {
      if (value % 12 === 0) {
        return `${2018 + Math.floor(value / 12)}${12}`
      } else if (value % 12 < 10) {
        return `${2018 + Math.floor(value / 12) + 1}0${value % 12}`
      } else {
        return `${2018 + Math.floor(value / 12) + 1}${value % 12}`
      }
    }
    if (map) {
      let filter = null
      if (showRecent === "1") {
        const now = parseInt(moment().format("YYYYMMDDHHmm")) // from today
        const recent7days = parseInt(
          moment().subtract(7, "days").format("YYYYMMDDHHmm")
        ) // seven days
        const eventFilter = [
          "all",
          [">=", ["number", ["get", "time"]], now],
          ["==", ["string", ["get", "type"]], "event"],
        ]
        const workFilter = [
          "all",
          [">=", ["number", ["get", "time"]], recent7days],
          ["==", ["string", ["get", "type"]], "work"],
        ]
        const circleFilter = [
          "all",
          [">=", ["number", ["get", "time"]], recent7days],
          [
            "any",
            ["==", ["string", ["get", "type"]], "market"],
            ["==", ["string", ["get", "type"]], "circle"],
          ],
        ]
        filter = ["any", eventFilter, workFilter, circleFilter]
      } else if (showRecent === "2") {
        const eventMonth = parseInt(convertMonth(recentFilter.event))
        const workMonth = parseInt(convertMonth(recentFilter.work))
        const circleMonth = parseInt(convertMonth(recentFilter.circle))
        const eventFilter = [
          "all",
          ["==", ["number", ["get", "month"]], eventMonth],
          ["==", ["string", ["get", "type"]], "event"],
        ]
        const workFilter = [
          "all",
          ["==", ["number", ["get", "month"]], workMonth],
          ["==", ["string", ["get", "type"]], "work"],
        ]
        const circleFilter = [
          "all",
          ["==", ["number", ["get", "month"]], circleMonth],
          [
            "any",
            ["==", ["string", ["get", "type"]], "market"],
            ["==", ["string", ["get", "type"]], "circle"],
          ],
        ]
        filter = ["any", eventFilter, workFilter, circleFilter]
      } else {
        filter = null
      }
      map.setLayoutProperty(
        "latestMixedLayer",
        "visibility",
        showRecent !== "0" ? "visible" : "none"
      )
      map.setLayoutProperty(
        "mixedLayer",
        "visibility",
        showRecent !== "0" ? "visible" : "none"
      )
      map.setFilter("latestMixedLayer", filter)
      map.setFilter("mixedLayer", filter)
    }
  }, [recentFilter, showRecent, map])

  useEffect(() => {
    if (map) {
      map.setLayoutProperty(
        "raster-satellite-streets",
        "visibility",
        satellite ? "visible" : "none"
      )
    }
  }, [satellite, map])

  useEffect(() => {
    if (map) {
      const layer = map.getLayer("specified-areas-of-northern")
      if (layer) {
        map.setLayoutProperty(
          "specified-areas-of-northern",
          "visibility",
          specialNorth ? "visible" : "none"
        )
      }
    }
  }, [specialNorth, map])

  useEffect(() => {
    if (map) {
      const layer = map.getLayer("specified-a")
      if (layer) {
        map.setLayoutProperty(
          "specified-a",
          "visibility",
          specialA ? "visible" : "none"
        )
      }
    }
  }, [specialA, map])

  useEffect(() => {
    if (map) {
      const layer = map.getLayer("specified-bushfire")
      if (layer) {
        map.setLayoutProperty(
          "specified-bushfire",
          "visibility",
          specialB ? "visible" : "none"
        )
      }
    }
  }, [specialB, map])

  useEffect(() => {
    if (map) {
      const layer = map.getLayer("harvest")
      if (layer) {
        map.setLayoutProperty(
          "harvest",
          "visibility",
          harvest ? "visible" : "none"
        )
      }
    }
  }, [harvest, map])

  useEffect(() => {
    if (map) {
      const farmlayer = map.getLayer("farm")
      if (farmlayer) {
        map.setLayoutProperty("farm", "visibility", farm ? "visible" : "none")
      }
      const farmiconlayer = map.getLayer("farms")
      if (farmiconlayer) {
        map.setLayoutProperty("farms", "visibility", farm ? "visible" : "none")
      }
    }
  }, [farm, map])

  return (
    <>
      <ItemsList
        selectedFeatures={selectedFeatures}
        setFeatures={setFeatures}
        selectedPoint={selectedPoint}
        setSelectedPoint={setSelectPoint}
        setRefreshLatest={setRefreshLatest}
        cate={cate}
        itemId={item_id}
        history={history}
        pathname={pathname}
        flyTo={flyTo}
      />
      <>
        <div className={classes.firstLineButton} data-tour="toolbar">
          <Tooltip title={f({ id: "showsatellite" })} data-tour="satellite">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setSatellite(!satellite)}
            >
              <Satellite style={{ fill: satellite ? "#00ff00" : "inherit" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={f({ id: "shownorth" })} data-tour="specialnorth">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setSpecialNorth(!specialNorth)}
            >
              <Hotel style={{ fill: specialNorth ? "#f6860e" : "inherit" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={f({ id: "showsa" })} data-tour="speciala">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setSpecialA(!specialA)}
            >
              <NaturePeople
                style={{ fill: specialA ? "#8bf9e1" : "inherit" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={f({ id: "showsb" })} data-tour="specialb">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setSpecialB(!specialB)}
            >
              <Whatshot style={{ fill: specialB ? "#f83030" : "inherit" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={f({ id: "harvestmap" })} data-tour="harvestmap">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setHarvest(!harvest)}
            >
              <EventAvailable
                style={{ fill: harvest ? "#de8f45" : "inherit" }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title={f({ id: "farmlist" })} data-tour="farmlist">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setFarm(!farm)}
            >
              <FarmIcon style={{ fill: farm ? "#131fcd" : "inherit" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              showMixedLayer
                ? f({ id: "hidemixedlayer" })
                : f({ id: "showmixedlayer" })
            }
            data-tour="iwhver"
          >
            <IconButton
              color="primary"
              variant="outlined"
              onClick={() => setShowMixedLayer(!showMixedLayer)}
            >
              <FilterList
                style={{ fill: showMixedLayer ? "#9170b4" : "inherit" }}
              />
            </IconButton>
          </Tooltip>
        </div>
        {showMixedLayer && (
          <div className={classes.sliders}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="showRecent"
                name="showRecent"
                value={showRecent}
                style={{
                  width: "auto",
                  height: "auto",
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                }}
                onChange={(e) => setShowRecent(e.target.value)}
              >
                <Tooltip title={f({ id: "hideallmixedlayer" })}>
                  <FormControlLabel
                    value="0"
                    labelPlacement="bottom"
                    control={
                      <Radio
                        classes={{ colorSecondary: classes.colorSecondary }}
                      />
                    }
                    label="Hide"
                  />
                </Tooltip>
                <Tooltip title={f({ id: "showrecent7" })}>
                  <FormControlLabel
                    value="1"
                    labelPlacement="bottom"
                    control={
                      <Radio
                        classes={{ colorSecondary: classes.colorSecondary }}
                      />
                    }
                    label="Recent"
                  />
                </Tooltip>
                <Tooltip title={f({ id: "showbasedm" })}>
                  <FormControlLabel
                    value="2"
                    labelPlacement="bottom"
                    control={
                      <Radio
                        classes={{ colorSecondary: classes.colorSecondary }}
                      />
                    }
                    label="Monthly"
                  />
                </Tooltip>
                <Tooltip title={f({ id: "showall" })}>
                  <FormControlLabel
                    value="3"
                    labelPlacement="bottom"
                    control={
                      <Radio
                        classes={{ colorSecondary: classes.colorSecondary }}
                      />
                    }
                    label="All"
                  />
                </Tooltip>
              </RadioGroup>
            </FormControl>
            <div
              style={{
                display: showRecent === "2" ? "block" : "none",
                marginTop: 5,
              }}
            >
              <Typography gutterBottom variant="body2">
                <img src={eventLogo} width="36" alt="event" />
                {f({ id: "event" })}
              </Typography>
              <IwhverSlider
                min={0}
                max={defaultMaxSlider}
                valueLabelDisplay="on"
                defaultValue={defaultMaxSlider - 12}
                marks={marks}
                valueLabelFormat={valuetext}
                value={recentFilter.event}
                className={classes.slider}
                onChange={valueSlider("event")}
              />

              <Typography gutterBottom variant="body2">
                <img src={workLogo} width="36" alt="work" />
                {f({ id: "work" })}
              </Typography>
              <IwhverSlider
                min={0}
                max={defaultMaxSlider}
                valueLabelDisplay="on"
                defaultValue={defaultMaxSlider - 12}
                marks={marks}
                value={recentFilter.work}
                valueLabelFormat={valuetext}
                className={classes.slider}
                onChange={valueSlider("work")}
              />

              <Typography gutterBottom variant="body2">
                <img src={circleLogo} width="36" alt="circle" />
                {f({ id: "circle" })}
              </Typography>
              <IwhverSlider
                min={0}
                max={defaultMaxSlider}
                valueLabelDisplay="on"
                defaultValue={defaultMaxSlider - 12}
                marks={marks}
                value={recentFilter.circle}
                valueLabelFormat={valuetext}
                className={classes.slider}
                onChange={valueSlider("circle")}
              />
            </div>
          </div>
        )}
        <div
          className={[
            isMobile
              ? classes.addLocationMobile
              : selectedPoint.lat === 0 && selectedPoint.lng === 0
              ? classes.addLocation
              : classes.addLocationLeft,
            "mapboxgl-ctrl",
            "mapboxgl-ctrl-group",
          ].join(" ")}
        >
          <Tooltip title={f({ id: "postinfo" })} data-tour="postinfo">
            <IconButton
              color="primary"
              // variant="outlined"
              onClick={setSelectPointClick}
            >
              <AddLocation style={{ fill: "inherit" }} size="medium" />
            </IconButton>
          </Tooltip>
        </div>
        {isMobile && (
          <div
            className={[
              classes.scrollTop,
              "mapboxgl-ctrl",
              "mapboxgl-ctrl-group",
            ].join(" ")}
          >
            <Tooltip title="Back to top">
              <IconButton
                color="primary"
                // variant="outlined"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <ExpandLess style={{ fill: "inherit" }} size="medium" />
              </IconButton>
            </Tooltip>
          </div>
        )}

        <div
          ref={(el) => (mapContainer.current = el)}
          className={
            selectedFeatures.length ||
            (selectedPoint.lat !== 0 && selectedPoint.lng !== 0)
              ? classes.mapItems
              : classes.map
          }
        />
      </>
    </>
  )
}

export default MapboxGLMap
