import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    CircularProgress
} from "@material-ui/core"
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withStyles } from "@material-ui/core"
import TextField from "./TextField"
import { apiPost } from "lib/api-call"
import { useIntl } from "react-intl"
import { isMobile } from "react-device-detect"

const styles = theme => ({
    root: {
        position: "fixed",
        zIndex: 10,
        right: theme.spacing(0),
        bottom: theme.spacing(3),
    },
    loading: {
        color: "white"
    },
    fab: {
        backgroundColor: "white"
    },
    dialog: {
        backgroundColor: theme.palette.secondary.main,
        color: "white"
    },
    textField: {
        width: "100%",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        color: "white"
    },
    dialogTitle: {
        color: "white"
    },
    disabled: {
        color: "rgba(255, 255, 255, 0.3) !important"
    },
    typography: {
        color: "white"
    }
})

const FeedbackForm = props => {
    const { formatMessage: f } = useIntl()
    const { classes } = props
    const open = useSelector(state => state.auth.user.showFeedback)
    const dispatch = useDispatch()
    const [feedback, setFeedback] = useState("")
    const [helpText, setHelpText] = useState(null)
    const [index, setIndex] = useState(Math.floor(Math.random() * 10))
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        setLoading(true)
        const result = await apiPost(`/v1/web/feedback/`, {
            feedback
        })
        setLoading(false)
        const { status } = result || {}
        if (!status) {
            setHelpText(f({ id: "help-feedbackSuccess" }))
            setFeedback("")
            setError(false)
        } else {
            setHelpText(f({ id: "help-feedbackError" }))
            setError(true)
        }
    }

    const handleClose = () => {
        dispatch({ type: "USER_SHOW_FEEDBACK_FORM" })
        setFeedback("")
        setIndex(Math.floor(Math.random() * 10))
    }
    const handleChange = e => {
        setFeedback(e.target.value)
    }
    const feedbackValid = feedback.length > 5
    return (
        <div className={classes.root} key={index}>
            <Dialog
                open={open}
                PaperProps={{
                    style: {
                        backgroundColor: "#9170b4",
                        boxShadow: "none",
                        minWidth: isMobile ? 300 : 600
                    }
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                <span style={{color: 'white'}}>{f({ id: "help-feedbackTitle" })}</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogTitle}>
                        <span style={{color: 'white'}}>{f({ id: "help-feedbackDesc" })}</span>
                    </DialogContentText>
                    <TextField
                        placeholder={f({ id: "help-feedbackPlaceholder" })}
                        value={feedback}
                        onChange={handleChange}
                        fullWidth
                        helperText={helpText}
                        multiline
                        rows={5}
                        FormHelperTextProps={{
                            style: {
                                color: error ? "red": "white"
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        className={classes.dialogTitle}
                        onClick={handleClose}
                        color="primary"
                    >
                        {f({ id: "close" })}
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        classes={{
                            disabled: classes.disabled
                        }}
                        className={classes.dialogTitle}
                        disabled={!feedbackValid}
                    >
                        {loading && <CircularProgress size={18} color="primary" classes={{colorPrimary: classes.loading}} />}
                        {!loading &&  f({ id: "send" })}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const FeedbackCom = withStyles(styles, { withTheme: true })(FeedbackForm)

export default FeedbackCom
