import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
} from "@material-ui/core"
import {
  countryCode,
  encountryCode,
  auStateChoices,
  nzStateChoices,
  stateChoices,
  enstateChoices,
  workCates,
  enworkCates,
  payModes,
  enpayModes
} from "lib/constants"
import { apiPost } from "lib/api-call"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    width: "100%",
  },
  selectTable: {
    width: "100%",
    color: "grey",
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    width: "100%",
    marginTop: 5,
  },
  checkFormControl: {
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    "&:focus, &:hover, &$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const WorkCreate = (props) => {
  const { formatMessage: f } = useIntl()
  const en = useSelector((state) => state.auth.user.initEn)
  const selectedPoint = props?.selectedPoint
  const setRefreshLatest = props?.setRefreshLatest
  const setSelectedPoint = props?.setSelectedPoint
  const [loading, setLoading] = useState(false)
  const [posted, setPosted] = useState(false)
  const [error, setError] = useState(false)
  const [desc, setDesc] = useState("")
  const [country, setCountry] = useState("AU")
  const [state, setState] = useState("VIC")
  const [category, setCategory] = useState("PA")
  const [tax, setTax] = useState(true)
  const [secondYear, setSecondYear] = useState(false)
  const [payrate, setPayrate] = useState(20)
  const [payMode, setPayMode] = useState("c")
  const [contact, setContact] = useState("")
  const [location, setLocation] = useState("")
  const selectChange = (kind) => (e) => {
    if (kind === "country") {
      setCountry(e.target.value)
      setState(e.target.value === "AU" ? "VIC" : "BD")
    } else if (kind === "state") {
      setState(e.target.value)
    } else if (kind === "category") {
      setCategory(e.target.value)
    } else if (kind === "paymode") {
      setPayMode(e.target.value)
    }
    setPosted(false)
  }

  const changeDesc = (e) => {
    setDesc(e.target.value)
    setPosted(false)
  }

  const handleCreateWork = async () => {
    if (loading || posted) return
    if (desc) {
      const data = {
        desc,
        country,
        state,
        second_year: secondYear,
        category,
        location,
        contact,
        pay_tax: tax,
        pay_mode: payMode,
        pay_rate: payrate,
        gps: `${selectedPoint.lat},${selectedPoint.lng}`,
      }
      setLoading(true)
      const res = await apiPost("/v1/web/create_work/", data)
      setLoading(false)
      const { code } = res
      if (code !== 0) {
        setError(true)
      } else {
        setPosted(true)
        setTimeout(() => {
          setRefreshLatest(true)
          setSelectedPoint({ lat: 0, lng: 0 })
        }, 1000)
      }
    }
  }
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <TextField
          required
          fullWidth
          label={f({ id: "workDesc" })}
          multiline
          rows={4}
          value={desc}
          placeholder={f({ id: "workDesc" })}
          variant="outlined"
          onChange={changeDesc}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "country" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={country}
          onChange={selectChange("country")}
        >
          {Object.keys(countryCode).map((key) => (
            <MenuItem key={key} value={key}>
              {en ? encountryCode[key] : countryCode[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "state" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={state}
          onChange={selectChange("state")}
        >
          {Object.keys(country === "AU" ? auStateChoices : nzStateChoices).map(
            (key) => (
              <MenuItem key={key} value={key}>
                {en ? enstateChoices[key] : stateChoices[key]}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "inducate" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={category}
          onChange={selectChange("category")}
        >
          {Object.keys(workCates).map((key) => (
            <MenuItem key={key} value={key}>
              {en ? enworkCates[key] : workCates[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.checkFormControl}>
        <FormControlLabel
          control={
            <Checkbox
              checked={tax}
              onChange={() => setTax(!tax)}
              name="tax"
              color="default"
            />
          }
          label={f({ id: "paytax" })}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={secondYear}
              onChange={() => setSecondYear(!secondYear)}
              name="secondYear"
              color="default"
            />
          }
          label={f({ id: "secondyear" })}
        />
      </div>
      <div className={classes.formControl}>
        <TextField
          label={f({ id: "salary" })}
          type="number"
          value={payrate}
          onChange={(e) => setPayrate(e.target.value)}
          style={{ width: "70%" }}
        />
        <Select
          className={classes.selectTable}
          value={payMode}
          onChange={selectChange("paymode")}
          style={{ width: "30%", height: "48px", margin: 0 }}
          variant="standard"
        >
          {Object.keys(payModes).map((key) => (
            <MenuItem key={key} value={key}>
              {en ? enpayModes[key]: payModes[key]}
            </MenuItem>
          ))}
        </Select>
      </div>
      <FormControl className={classes.formControl}>
        <TextField
          label={f({ id: "contact" })}
          value={contact}
          onChange={(e) => {
            setContact(e.target.value)
            setPosted(false)
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label={f({ id: "address" })}
          value={location}
          onChange={(e) => {
            setLocation(e.target.value)
            setPosted(false)
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="default"
          fullWidth
          onClick={handleCreateWork}
          className={classes.button}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            f({ id: "error-hint" })
          ) : posted ? (
            f({ id: "thank-your-post" })
          ) : (
            f({ id: "post" })
          )}
        </Button>
      </FormControl>
    </div>
  )
}

export default WorkCreate
