import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Link,
  Divider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Share, Favorite, Comment, Delete } from "@material-ui/icons"
import moment from "moment"
import { stateChoices, workCates, enworkCates } from "lib/constants"
import WorkInfoIcon from "assets/images/workinfo.png"
import { AUWORKOPENID } from "lib/constants"
import Editor from "./Editor"
import { endpoints } from "lib/end-points"
import { apiGet, apiPost } from "lib/api-call"
import { Badge } from "antd"
import ShareDialog from "components/ShareDialog/index"
import DeleteDialog from "components/DeleteDialog/index"
import { isMobile } from "react-device-detect"
import CustomCommentCom from "./CustomCommentCom"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 5,
  },
  gridItem: {
    // padding: theme.spacing(2),
    paddingBottom: "0 !important",
  },
  desc: {
    marginBottom: 10,
    fontSize: "0.9rem",
  },
  name: {
    flex: 1,
    marginTop: 5,
    marginLeft: 5,
  },
  card: {
    width: isMobile ? "calc(100vw - 16px) !important" : 334,
  },
  listItem: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: "white",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardAction: {
    paddingBottom: 6,
    paddingTop: 2,
  },
  comment: {
    paddingTop: 2,
    paddingBottom: 2,
  },
}))

const Work = (props) => {
  const classes = useStyles()
  const en = useSelector((state) => state.auth.user.initEn)
  const { formatMessage: f } = useIntl()
  const openid = useSelector((state) => state.auth.user.openid)
  const shareItem = props?.shareItem
  const item =
    props.item?.properties?.context &&
    JSON.parse(props.item?.properties?.context)
  const listItem = props?.listItem
  const context = shareItem || item || listItem

  const id = context?.id
  const [zan, setZan] = useState(false)
  const [showComment, setShowComment] = useState(false)
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [replyid, setReplyid] = useState(null)
  const [replyto, setReplyto] = useState(null)
  const [initQuery, setInitQuery] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [placeholder, setPlaceholder] = useState(
    `Reply to: ${
      context.creator?.profile?.nickname === "澳打君工作"
        ? "网络情报"
        : context.creator?.profile?.nickname
    }`
  )
  const [showDelete, setShowDelete] = useState(false)
  const [deleteContent, setDeleteContent] = useState(null)
  const [deleted, setDeleted] = useState(false)

  const changeComment = (e) => {
    setComment(e.target.value)
  }

  const submitComment = async () => {
    if (!submitting && comment) {
      setSubmitting(true)
      const data = {
        comment: comment,
        cate: "work",
        id: context?.id,
        reply_id: replyid,
        reply_to: replyto,
      }
      const res = await apiPost(endpoints.comment, data)
      if (res?.id) {
        setComments([...comments, res])
      }
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (showComment) {
      apiGet(`${endpoints.comment}?cate=work&id=${id}`).then((res) => {
        if (res?.code === 0) {
          setComments(res.comments)
        }
      })
    }
  }, [showComment, setComment, id])

  useEffect(() => {
    const timer = () => {
      setTimeout(
        () =>
          apiGet(
            `${endpoints.zan_check}?cate=work&action=check&id=${context?.id}`
          ).then((res) => setZan(res.zan)),
        Math.floor(Math.random() * Math.floor(5000))
      )
    }
    if (!initQuery) {
      timer()
      setInitQuery(true)
    }
  }, [context, initQuery, setInitQuery])

  const zanClick = () => {
    apiGet(
      `${endpoints.zan_check}?cate=work&action=add&id=${context?.id}`
    ).then((res) => setZan(res.zan))
  }

  const replyClick = (id, commentter) => () => {
    setReplyid(id)
    setReplyto(commentter?.openid)
    setPlaceholder(
      `Reply to: ${
        commentter?.profile.nickname === "澳打君工作"
          ? "网络情报"
          : commentter?.profile.nickname
      }`
    )
  }

  const deleteClick = (id) => () => {
    setShowDelete(true)
    setDeleteContent(id)
  }

  const handleCloseDelete = (deleted = false) => () => {
    setShowDelete(false)
    if (deleted) {
      setDeleted(true)
    }
  }

  return (
    <>
      {deleted ? null : (
        <>
          <ShareDialog
            cate="work"
            itemId={id}
            open={shareOpen}
            handleClose={() => setShareOpen(false)}
          />
          <DeleteDialog
            cate="work"
            itemId={deleteContent}
            handleClose={handleCloseDelete}
            open={showDelete}
          />
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              <Card className={listItem ? classes.listItem : classes.card}>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="iwhver"
                      className={classes.avatar}
                      src={
                        context.creator?.openid === AUWORKOPENID
                          ? WorkInfoIcon
                          : context.creator?.profile?.avatarUrl
                      }
                    />
                  }
                  title={`${stateChoices[context.state]} | ${
                    en
                      ? enworkCates[context.category]
                      : workCates[context.category]
                  } | ${f({ id: "secondyear" })}：${
                    context.second_year ? f({ id: "yes" }) : f({ id: "no" })
                  } | ${f({ id: "paytax" })}: ${
                    context.pay_tax ? f({ id: "yes" }) : f({ id: "no" })
                  }`}
                  subheader={`${moment(context.created_on).format(
                    "YYYY MMM Do h:mm a"
                  )}`}
                />
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {context.desc}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {f({ id: "address" })}: {context.location}
                  </Typography>
                  {context.contact && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {f({ id: "contact" })}：{context.contact}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {f({ id: "salary" })}：
                    {context.pay_rate === 0
                      ? f({ id: "contactbyyou" })
                      : `${context.pay_rate}/${
                          context.pay_mode === "h"
                            ? f({ id: "h" })
                            : f({ id: "c" })
                        }`}
                  </Typography>
                  {context.source && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      <Link href={`${context.source}`} target="_blank">
                        {context.source}
                      </Link>
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{ marginTop: 5 }}
                  >
                    ID: #{context.id} | {f({ id: "creator" })}：
                    {context.creator?.profile?.nickname === "澳打君工作"
                      ? f({ id: "fromnet" })
                      : context.creator?.profile?.nickname}
                  </Typography>
                  <Divider className={classes.divider} />
                  {showComment && (
                    <>
                      {comments.length
                        ? comments.map((comment) => (
                            <CustomCommentCom
                              comment={comment}
                              replyClick={replyClick}
                              cate="work"
                              showDeleteButton={
                                openid === comment?.user?.openid
                              }
                            />
                          ))
                        : null}
                      <Editor
                        value={comment}
                        onChange={changeComment}
                        onSubmit={submitComment}
                        submitting={submitting}
                        placeholder={placeholder}
                      />
                    </>
                  )}
                </CardContent>
                <CardActions disableSpacing className={classes.cardAction}>
                  <IconButton
                    aria-label="add to favorites"
                    onClick={zanClick}
                    size="small"
                  >
                    <Favorite color={zan ? "primary" : "action"} />
                  </IconButton>
                  <IconButton
                    aria-label="share"
                    onClick={() => setShareOpen(true)}
                    size="small"
                  >
                    <Share />
                  </IconButton>
                  {context?.creator?.openid === openid && (
                    <IconButton size="small" onClick={deleteClick(context?.id)}>
                      <Delete />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="Go"
                    className={classes.expand}
                    onClick={() => {
                      setShowComment(!showComment)
                      setReplyid(null)
                      setReplyto(null)
                      setPlaceholder(
                        `Reply to: ${
                          context.creator?.profile?.nickname === "澳打君工作"
                            ? f({ id: "fromnet" })
                            : context.creator?.profile?.nickname
                        }`
                      )
                    }}
                    size="small"
                  >
                    <Badge
                      count={comments.length || context.comment || 0}
                      style={{ backgroundColor: "#52c41a" }}
                    >
                      <Comment />
                    </Badge>
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default Work
