import React from "react"
//import PropTypes from 'prop-types'
import { Route, Switch } from "react-router-dom"

import FourOFour from "components/404"
import Login from "./Login"

const Auth = (props) => {
  const location = props?.location
  return (
    <Switch>
      <Route
        path={`${props.match.url}/login`}
        render={(props) => <Login {...props} location={location} />}
      />
      <Route render={(props) => <FourOFour {...props} />} />
    </Switch>
  )
}

export default Auth
