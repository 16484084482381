import { LEFT_MENU_TOGGLE } from "./constants"

const initialState = {
  leftToggle: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LEFT_MENU_TOGGLE:
      return {
        ...state,
        leftToggle: !state.leftToggle
      }

    default:
      return state
  }
}

export default reducer
