import React from "react"
import ReactDOM from "react-dom"

import { applyMiddleware, createStore, compose } from "redux"
import { Provider } from "react-redux"
import createSagaMiddleware from "redux-saga"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import Tour from "components/Tour/index"
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary"
import IndexReducer from "./index-reducer"
import IndexSagas from "./index-sagas"
import "./index.css"
import "mapbox-gl/dist/mapbox-gl.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

const sagaMiddleware = createSagaMiddleware()

const composeSetup =
  process.env.NODE_ENV !== "production" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const store = createStore(
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)) // allows redux devtools to watch sagas
)

sagaMiddleware.run(IndexSagas)

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#9170b4",
    },
    secondary: {
      main: "#ffffff",
    },
  },
})

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <MuiThemeProvider theme={customTheme}>
        <Tour />
        <App />
      </MuiThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
