const common = {
  signOut: "Logout",
  profile: "Profile",
  mapmode: "Map Mode",
  listmode: "List Mode",
  minemode: "Mine List",
  "help-feedbackTitle": "Feedback, or request to delete your data",
  "help-feedbackDesc": "Thank you for your feedback or request, we will respond accordingly within two business days.",
  "help-feedbackPlaceholder":
    "Thank you for your report about the bugs, advice, feedback, or information. If you want to delete your data in our server, we will respond to it within two business days",
  "help-feedbackSuccess":
    "We have received your feedback or request, we will respond to it soon, thank you very much.",
  "help-feedbackError": "Something wrong, try it again",
  "close": "Close",
  "send": "Send",
  "mainNav-analysis": "Main Menu",
  "map": "Map Mode",
  "list": "List Mode",
  "mine": "Personal Mode",
  "switchEn": "Switch Language",
  "guide": "Click to check the guideline",
  "login": "Login",
  "sharetof": "Click to copy and share with your friend",
  "copytoclip": "Copied to clipboard!",
  "copy": "Copy",
  "yourthought": "Your thought now",
  "farmdesc": "Introduce the farm",
  "farmplaceholder": "the farm details: contact info, website, address, harvest season, and etc; We will check and add to the map ASAP",
  "contact": "Contact",
  "address": "Address",
  "error-hint": "Error, please retry",
  "thank-your-post": "Thank you for your post",
  "post": "Post",
  "circle": "Posts",
  "work": "Work",
  "farm": "Farm",
  "market": "Market",
  "content": "Content",
  "sell-buy": "Want/Sell",
  "country": "Country",
  "category": "Category",
  "workDesc": "Work description",
  "state": "State",
  "inducate": "Industry category",
  "paytax": "Pay tax",
  "secondyear": "SecondYearVisa",
  "salary": "Salary",
  "h": "Hourly",
  "c": "Piece wage",
  'creator': "Creator",
  'yes': "Yes",
  "no": "No",
  "contactbyyou": "Confirm by yourself",
  "fromnet": "from Internet",
  "notlogin": "You are not yet logged in, login to check more content",
  "loginclick": "Click to login",
  "fromshare": "From share: ",
  "notfoundmap": "The content you are looking for no longer exist, find some more interesting things on the map~",
  "loadmore": "Load more...",
  "thismonth": "This month",
  "showsatellite": "Show satellite",
  "shownorth": "Show Specified areas of northern Australia",
  "showsa": "Specified areas of regional Australia",
  "showsb": "Bushfire affected areas",
  "harvestmap": "Harvest Map",
  "farmlist": "Farm list",
  "showmixedlayer": "Filter posts/works/markets/events",
  "hidemixedlayer": "Hide posts/works/markets/events",
  "hideallmixedlayer": "Hide all posts/works/markets/events",
  "showrecent7": "Only show recent 7 days of posts/works/markets/events",
  "showbasedm": "Show posts/works/markets/events based on month",
  "showall": "Show all posts/works/markets/events",
  "event": "Events",
  "postinfo": "Post works/thoughts/markets or report farm information",
  "minepost": "Posted by me",
  "minecomment": "Comment by me",
  "minesave": "Save by me",
  "nothinghere": "There is nothing here."
}

const loginView = {
  loginAsGuest: "Continue as guest",
  scanned: "Scanned",
  scanWithWechat: "Please use Wechat to scan the QR code",
  iwhvername: "@iwhver",
}

export default {
  ...common,
  ...loginView,
}
