import React, { useState, useEffect } from "react"
import {
  Divider,
  FormControl,
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { v4 as uuidv4 } from "uuid"
import { Link } from "react-router-dom"
import AuwhverLogo from "assets/images/iwhver.png"
import { apiGet, apiPost } from "lib/api-call"
import { backendEndpoint } from "lib/constants"
import { endpoints } from "lib/end-points"
import queryString from "query-string"
import { isMobileOnly } from "react-device-detect"
import FacebookLogin from "react-facebook-login"
import { useIntl } from "react-intl"


const LoginView = (props) => {
  const { formatMessage: f } = useIntl()
  const location = props?.location
  const loginOpenid = props?.loginOpenid
  const params = queryString.parse(location?.search)
  const paramOpenid = params?.openid
  const admin = params?.admin
  const getCode = () => uuidv4().toString().split("-").join("")
  const [code, setCode] = useState(getCode())
  const [qCode, setQcode] = useState(true)
  const [qCodeLoading, setQcodeLoading] = useState(true)
  const [qCodeStatus, setQcodeStatus] = useState("W")
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    openid: "",
  })

  const imageUrl = `${backendEndpoint}/v1/web/minicode/?code=${code}&isMobile=${isMobileOnly}`

  useEffect(() => {
    const setOpenid = (openid) => {
      setFormData({
        ...formData,
        openid,
      })
    }
    if (paramOpenid) {
      apiGet(`/v1/web/check_minicode/?openid=${paramOpenid}`).then((res) => {
        const token = res?.token
        if (token) {
          loginOpenid(token, paramOpenid)
        }
      })
    }
    const interval = setInterval(async () => {
      if (!qCodeLoading && qCode && !formData.openid) {
        const res = await apiGet(`/v1/web/check_minicode/?code=${code}`)
        const { data: { status, wechat_user } = {}, token } = res || {}
        setQcodeStatus(status)
        if (wechat_user && status === "S") {
          setOpenid(wechat_user.openid)
          loginOpenid(token, wechat_user.openid)
        }
      }
    }, 4000)
    return () => clearInterval(interval)
  }, [
    qCodeLoading,
    code,
    formData,
    qCode,
    formData.openid,
    loginOpenid,
    paramOpenid,
  ])

  const handleSubmit = (event) => {
    event.preventDefault()
    props.loginRequest(formData.email, formData.password, formData.openid)
  }

  const checkFormValid = () =>
    formData.email.length > 0 && formData.password.length > 0

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    })
  }

  const qCodeLoaded = () => {
    setQcodeLoading(false)
  }

  const switchLoginWay = () => {
    setQcode(!qCode)
    setCode(getCode())
    setQcodeLoading(true)
  }

  const setOpenid = (openid) => {
    setFormData({
      ...formData,
      openid,
    })
  }

  const responseFacebook = (e) => {
    apiPost(endpoints.fblogin, e).then(res => {
      const {token, openid} = res || {}
      if (openid && token) {
        setOpenid(openid)
        loginOpenid(token, openid)
      }
    })
  }

  return (
    <Grid
      container
      justify="center"
      style={{ paddingTop: "10vh", overflow: "hidden" }}
    >
      <Grid item md={3} sm={5} xs={11}>
        <Paper style={{ padding: 30, textAlign: "center" }}>
          <img
            src={AuwhverLogo}
            alt="Auwhver icon"
            style={{ margin: 24, width: 250 }}
          />
          {admin && (
            <Button
              color="primary"
              variant="contained"
              id="changeLoginButton"
              aria-label="change-login"
              style={{ marginTop: 10, marginBottom: 10 }}
              data-testid="change-login"
              onClick={switchLoginWay}
            >
              {qCode ? "切换账户密码登陆" : "切换小程序扫码登陆"}
            </Button>
          )}
          <Link to="/dashboard/map/">
            <Button
              color="primary"
              variant="contained"
              id="changeLoginButton"
              aria-label="change-login"
              style={{ marginTop: 10, marginBottom: 10 }}
              data-testid="change-login"
            >
              {f({id: "loginAsGuest"})}
            </Button>
          </Link>
          <FacebookLogin
            appId="2616473261942804"
            size="small"
            fields="name,email,picture"
            callback={responseFacebook}
            icon="fa-facebook"
            scope="public_profile"
          />
          <Divider style={{marginTop: 10}}/>
          {!qCode && (
            <form onSubmit={handleSubmit.bind(this)}>
              <FormControl style={{ width: "90%" }}>
                <TextField
                  autoFocus={true}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  id="email"
                  value={formData.email}
                  label="Username"
                  onChange={handleChange}
                  fullWidth
                />
                <TextField
                  type="password"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  id="password"
                  value={formData.password}
                  label="Password"
                  onChange={handleChange}
                  fullWidth
                />
                <Button
                  color="primary"
                  variant="contained"
                  id="submitButton"
                  aria-label="submit-login"
                  style={{ marginTop: 10, marginBottom: 10 }}
                  disabled={!checkFormValid()}
                  type="submit"
                  data-testid="username-input"
                >
                  Login
                </Button>
                <Link
                  to="/auth/password/"
                  target="__blank"
                  style={{ textDecoration: "none" }}
                >
                  <Typography style={{ fontSize: "0.8em" }}>
                    Lost your password?
                  </Typography>
                </Link>
              </FormControl>
            </form>
          )}
          {qCode && (
            <>
              {qCodeLoading && (
                <div
                  style={{
                    height: 300,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              <img
                src={imageUrl}
                onLoad={qCodeLoaded}
                alt="小程序扫码"
                style={{
                  margin: "auto",
                  width: 300,
                  display: qCodeLoading ? "none" : "block",
                }}
              />
              <div style={{ padding: 20 }}>
                {qCodeStatus === "C" && (
                  <Typography color="secondary">{f({id: "scanned"})}</Typography>
                )}
                <Typography color="textPrimary">
                  {f({id: "scanWithWechat"})}
                </Typography>
                <Typography color="textSecondary">{f({id: "iwhvername"})}</Typography>
                <a href="/privacy.html" target="__blank"><Typography color="textSecondary" href="/privacy.html">{"Privacy Policy"}</Typography></a>
              </div>
            </>
          )}
          <div style={{ padding: 10 }}>
            {props.requesting && <CircularProgress aria-label="logging-in" />}
            {!props.requesting &&
              !!props.errors.length &&
              props.errors.map((error, index) => (
                <Typography key={index} variant="body2" color="error">
                  {error.body}
                </Typography>
              ))}
            {!props.requesting &&
              !!props.messages.length &&
              props.messages.map((message, index) => (
                <Typography key={index} variant="body2" color="error">
                  {message.body}
                </Typography>
              ))}
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default LoginView
