import React, { Component } from "react"
import { Button } from "@material-ui/core"
import { Link } from "react-router-dom"

export default class FourOFour extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          height: "85%",
          justifyContent: "center",
          flexDirection: "column"
        }}
      >
        <h1>Sory, 404 not Found </h1>
        <h1>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Button style={{ height: 40, backgroundColor: "#005bbd" }}>
              Back to Dashboard
            </Button>
          </Link>
        </h1>
      </div>
    )
  }
}
