import {
  USER_SET,
  USER_LOGOUT,
  USER_SET_PROFILE,
  USER_PROFILE_SHOW_TOUR,
  USER_PROFILE_SET_SHOW_TOUR,
  USER_SHOW_FEEDBACK_FORM,
  USER_CHANGE_EN
  // Need to add redducers for user api call
  //USER_PROFILE_REQUESTING,
  //USER_PROFILE_REQUEST_SUCCESS,
  //USER_PROFILE_REQUEST_ERROR
} from "./constants"

const language = navigator.language.split(/[-_]/)[0]

const initialSate = {
  userId: null,
  openid: null,
  loggedIn: false,
  profile: null,
  showTour: false,
  showFeedback: false,
  initEn: language === 'en'
}

const reducer = function userReducer(state = initialSate, action) {
  switch (action.type) {
    case USER_SET:
      return {
        ...state,
        userId: action.token.user_id,
        openid: action.token.openid,
        loggedIn: true
      }

    case USER_SET_PROFILE:
      return {
        ...state,
        profile: action.profile
      }

    case USER_PROFILE_SHOW_TOUR:
      return {
        ...state,
        showTour: !state.showTour
      }
    case USER_PROFILE_SET_SHOW_TOUR:
      return {
        ...state,
        showTour: action.shown
      }
    case USER_LOGOUT:
      return initialSate

    case USER_SHOW_FEEDBACK_FORM:
      return {
        ...state,
        showFeedback: !state.showFeedback
      }

    case USER_CHANGE_EN:
      return {
        ...state,
        initEn: !state.initEn
      }

    default:
      return state
  }
}

export default reducer
