import React, { Component } from "react"
import { Redirect, Route } from "react-router-dom"
import { connect } from "react-redux"
const PUBLIC_ROOT = "/dashboard"

class AuthRoute extends Component {
  render() {
    const { component: Component, loggedIn, path, ...rest } = this.props
    const openid = rest?.user?.openid

    if (loggedIn && !openid) {
      return path.includes("crm") ? (
        <Route {...rest} render={(props) => <Component {...props} />} />
      ) : (
        <Redirect
          to={{
            pathname: "/crm",
            search: this.props.location.search,
            state: { referrer: this.props.location },
          }}
        />
      )
    } else if (path.includes(PUBLIC_ROOT)) {
      return <Route {...rest} render={(props) => <Component {...props} />} />
    } else if (loggedIn && openid) {
      //If route is private, user proceeds, else route is public, redirect use to private root.
      return !path.includes("auth") ? (
        <Route {...rest} render={(props) => <Component {...props} />} />
      ) : (
        <Redirect
          to={{
            pathname: PUBLIC_ROOT,
            search: this.props.location.search,
            state: { referrer: this.props.location },
          }}
        />
      )
    } else {
      //If route is private, user is redirected to app's public root, else user proceeds.
      return path.includes("auth") ? (
        <Route {...rest} render={(props) => <Component {...props} />} />
      ) : (
        <Redirect
          to={{
            pathname: PUBLIC_ROOT,
            state: { referrer: this.props.location },
            search: this.props.location.search,
          }}
        />
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loggedIn: state.auth.user.loggedIn,
    logInSuccess: state.auth.login.successful,
  }
}

const connected = connect(mapStateToProps)(AuthRoute)

export default connected
