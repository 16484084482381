import { backendEndpoint } from "lib/constants"
const errorCodes = [
  { status: 400, statusText: "That request doesn't look quite right" },
  {
    status: 403,
    statusText: "You aren't currently authorised for this resource"
  },
  { status: 404, statusText: "We can't find what you're looking for" },
  {
    status: 500,
    statusText: "Looks like we can't connect to the server right now"
  },
  { status: 502, statusText: "We having trouble processing this request" },
  { status: 503, statusText: "We're down for a moment" }
]

export function apiGet(endpoint) {
  let params = { method: "GET" }
  return apiCall(endpoint, params)
}

export function apiPost(endpoint, payload) {
  let params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  }
  return apiCall(endpoint, params)
}

export function apiPut(endpoint, payload) {
  let params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  }
  return apiCall(endpoint, params)
}

export function apiPatch(endpoint, payload) {
  let params = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  }
  return apiCall(endpoint, params)
}

export default function apiCall(endpoint, params) {
  /* const url = (process.env.NODE_ENV === 'development') 
    ? `${endpoint}`
    : `${process.env.REACT_APP_PROD_API}${endpoint}` */
  const prodEndpoint = `${backendEndpoint}${endpoint}`
  let tokens = localStorage.getItem("token")
  if (tokens) {
    let tokensObject = JSON.parse(tokens)
    let accessToken = tokensObject.access
    if (!params.headers) {
      params.headers = {}
    }
    params.headers.Authorization = `Bearer ${accessToken}`
    //console.log("Api call to:", endpoint)
    //console.log("With:", params)
  }

  return fetch(prodEndpoint, params)
    .then(handleResponse)
    .then(data => data)
    .catch(error => {
      let errorCode = errorCodes.find(code => code.status === error.status)
      if (errorCode)
        return Promise.reject({
          status: errorCode.status,
          statusText: errorCode.statusText,
          error: JSON.stringify(error.error || {})
        })
      else
        return Promise.reject({
          status: error.status,
          statusText: error.statusText,
          error: JSON.stringify(error.error || {})
        })
    })
}

function handleResponse(response) {
  if (response.status > 400) return Promise.reject(response)
  let contentType = response.headers.get("content-type")
  if (contentType.includes("application/json")) {
    return handleJSONResponse(response)
  } else if (
    contentType.includes("text/html") ||
    contentType.includes("text/plain")
  ) {
    return handleTextResponse(response)
  } else {
    return Promise.reject(response)
    // Other response types as necessary. I haven't found a need for them yet though.
  }
}

function handleJSONResponse(response) {
  return response.json().then(json => {
    if (response.ok) {
      return json
    } else {
      return Promise.reject(
        Object.assign({}, json, {
          status: response.status,
          statusText: response.statusText
        })
      )
    }
  })
}

function handleTextResponse(response) {
  return response.text().then(text => {
    //console.log(response)
    if (response.ok) {
      return text
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        err: text
      })
    }
  })
}
