import React, { Component } from "react"
import PropTypes from "prop-types"
import { leftMenuToggle } from "../SideNavLeft/actions"
import { rightMenuToggle } from "../SideNavRight/actions"
import { connect } from "react-redux"
import HeaderBar from "./HeaderBar"
import { withRouter } from "react-router-dom"

import { userLogout } from "views/Auth/Profile/actions"

class Header extends Component {
  static propTypes = {
    leftMenuState: PropTypes.bool,
    leftMenuToggle: PropTypes.func.isRequired,
    rightMenuToggle: PropTypes.func.isRequired
  }

  render() {
    return (
      <div style={{ backgroundColor: "#011d33" }}>
        <HeaderBar
          rightMenuState={this.props.rightMenuState}
          leftMenuState={this.props.leftMenuState}
          leftMenuToggle={this.props.leftMenuToggle}
          logout={this.props.userLogout}
          rightMenuToggle={this.props.rightMenuToggle}
          auth={this.props.auth}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
//   leftMenuState: state.ui.leftNav.leftToggle,
//   rightMenuState: state.ui.rightNav.rightToggle,
  auth: state.auth
})

const mapDispatchToProps = {
  leftMenuToggle,
  rightMenuToggle,
  userLogout
}

const connected = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Header)
)

export default connected
