import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  TextField,
  FormControl,
  Button,
  CircularProgress,
} from "@material-ui/core"
import { useIntl } from "react-intl"
import { apiPost } from "lib/api-call"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    width: "100%",
  },
  formControl: {
    width: "100%",
    marginTop: 5,
  },
  button: {
    "&:focus, &:hover, &$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const FarmCreate = (props) => {
  const { formatMessage: f } = useIntl()
  const selectedPoint = props?.selectedPoint
  const [content, setContent] = useState("")
  const [loading, setLoading] = useState(false)
  const [posted, setPosted] = useState(false)
  const [error, setError] = useState(false)
  const [contact, setContact] = useState("")
  const [location, setLocation] = useState("")
  const handleCreateFarm = async () => {
    if (loading || posted) return
    if (content) {
      const data = {
        gps: `${selectedPoint.lat},${selectedPoint.lng}`,
        content,
        address: location,
        contact,
      }
      setLoading(true)
      const res = await apiPost("/v1/web/create_farm/", data)
      setLoading(false)
      const code = res?.code
      if (code !== 0) {
        setError(true)
      } else {
        setPosted(true)
      }
    }
  }
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        label={f({ id: "farmdesc" })}
        multiline
        rows={4}
        value={content}
        placeholder={f({ id: "farmplaceholder" })}
        variant="outlined"
        onChange={(e) => {
          setContent(e.target.value)
          setPosted(false)
        }}
      />
      <FormControl className={classes.formControl}>
        <TextField
          label={f({ id: "contact" })}
          value={contact}
          onChange={(e) => {
            setContact(e.target.value)
            setPosted(false)
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label={f({ id: "address" })}
          value={location}
          onChange={(e) => {
            setLocation(e.target.value)
            setPosted(false)
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button
          variant="contained"
          color="default"
          fullWidth
          onClick={handleCreateFarm}
          className={classes.button}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            f({ id: "error-hint" })
          ) : posted ? (
            f({ id: "thank-your-post" })
          ) : (
            f({ id: "post" })
          )}
        </Button>
      </FormControl>
    </div>
  )
}

export default FarmCreate
