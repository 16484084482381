import React from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"
import Divider from "@material-ui/core/Divider"
import { useIntl } from "react-intl"
import {
  Map as MapIcon,
  CloudUpload,
  Place,
  AddLocation,
  Router as RouterIcon,
  Dashboard,
  Person,
  Help,
  Payment,
  List as ListIcon
} from "@material-ui/icons"

import routes from "./navLinks"

const styles = {
  NavList: {
    width: "100%"
  },
  listItemText: {
    color: "#fafafa"
  },
  subheader: {
    color: "#fafafa75"
  },
  listIcon: {
    color: "#ffffff75"
  },
  childListIcon: {
    color: "#ffffff75",
    marginLeft: 20
  },
  subListItem: {
    paddingLeft: 40,
    backgroundColor: "#005bbd"
  },
  listItemActive: {
    backgroundColor: "#005bbd"
  }
}

function NavList(props) {
  const { formatMessage: f } = useIntl()
  const group = useSelector(state => state.auth.user.group)
  let navLinks = routes(group)
  const { classes } = props

  let iconList = {
    DashboardIcon: Dashboard,
    RouterIcon: RouterIcon,
    MapIcon: MapIcon,
    CloudUpload: CloudUpload,
    Place: Place,
    AddLocation: AddLocation,
    Person: Person,
    Help: Help,
    PaymentIcon: Payment,
    ListIcon: ListIcon
  }

  return (
    <div className={classes.NavList}>
      <List>
        {navLinks.map((item, index) => {
          let componentName = item.icon
          let Icon = iconList[componentName]
          switch (item.type) {
            case "subHeader":
              return (
                <ListSubheader
                  key={index}
                  classes={{ root: classes.subheader }}
                >
                  {f({ id: item.i18nId })}
                </ListSubheader>
              )
            case "listItem":
              return (
                <ListItem
                  key={index}
                  className={`${
                    props.currentUrl === item.link
                      ? classes.listItemActive
                      : classes.listItem
                  } ${item.stepKey}`}
                  component={Link}
                  to={item.link}
                >
                  <ListItemIcon classes={{ root: classes.childListIcon }}>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={f({ id: item.i18nId })}
                  />
                </ListItem>
              )
            case "divider":
              return <Divider key={index} />
            case "signOut":
              return (
                <ListItem key={index} button onClick={() => props.logout()}>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={f({ id: item.i18nId })}
                  />
                </ListItem>
              )
            case "subListItem": {
              return props.currentUrl.includes(item.link) ? (
                <List key={index}>
                  <ListItem className={classes.subListItem}>
                    <ListItemIcon classes={{ root: classes.childListIcon }}>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={f({ id: item.i18nId })}
                    />
                  </ListItem>
                </List>
              ) : null
            }
            default: {
              return null
            }
          }
        })}
      </List>
    </div>
  )
}

NavList.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NavList)
