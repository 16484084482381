import React, { useState } from "react"
import { IconButton, MenuItem, Menu } from "@material-ui/core"
import { Link } from "react-router-dom"
import { useIntl } from "react-intl"
import MenuIcon from "@material-ui/icons/Menu"
import { useSelector } from "react-redux"


export default () => {
  const loggedIn = useSelector((state) => state.auth.user.loggedIn)

  const { formatMessage: f } = useIntl()
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        color="inherit"
        data-tour="menu"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        className="step-account-menu"
        disabled={!loggedIn}
        onClick={loggedIn ? handleMenu : () => {}}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} to="/dashboard/map/" component={Link}>
          {f({ id: "mapmode" })}
        </MenuItem>
        <MenuItem onClick={handleClose} to="/dashboard/list/" component={Link}>
          {f({ id: "listmode" })}
        </MenuItem>
        <MenuItem onClick={handleClose} to="/dashboard/mine/" component={Link}>
          {f({ id: "minemode" })}
        </MenuItem>
      </Menu>
    </>
  )
}
