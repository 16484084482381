import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Paper,
  FormControl,
  Typography,
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Tooltip,
  FormControlLabel,
  TextField,
  InputLabel,
  Input,
} from "@material-ui/core"
import { AddToHomeScreen, Send } from "@material-ui/icons"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  textField: {
    margin: theme.spacing(1),
  },
  buttondiv: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}))

const ClientPreview = ({
  previewContent,
  handleDialogClose,
  showDialog,
  handleTest,
  handleSend,
}) => {
  const classes = useStyles()

  const [testContact, setTestContact] = useState({
    test_email: "446948216@qq.com",
    test_mobile: "18626216693",
    useContact: false,
  })
  const changeTestContact = (key) => (e) => {
    setTestContact({
      ...testContact,
      [key]: e.target.value,
    })
  }

  const changeChecked = (e) => {
    setTestContact({
      ...testContact,
      useContact: e.target.checked,
    })
  }

  return (
    <Dialog onClose={handleDialogClose} open={showDialog} fullWidth>
      <DialogTitle>预览</DialogTitle>
      <DialogContent>
        <Paper className={classes.root}>
          <Typography variant="h4" style={{ color: "red" }}>
            {previewContent?.hint}
          </Typography>
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={previewContent?.send_email || false}
                name="send_email"
                color="primary"
                disabled
              />
            }
            label="发邮件"
          />
          <TextField
            fullWidth
            value={previewContent?.subject || ""}
            className={classes.textField}
            disabled
            margin="normal"
            variant="outlined"
          />
          <TextField
            multiline
            rows="4"
            fullWidth
            value={previewContent?.email || ""}
            disabled
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={previewContent?.send_sms || false}
                name="send_sms"
                color="primary"
                disabled
              />
            }
            label="发短信"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={previewContent?.sms_api_key_ad || false}
                name="sms_api_key_ad"
                color="primary"
                disabled
              />
            }
            label="营销短信？"
          />
          <TextField
            multiline
            rows="4"
            fullWidth
            value={previewContent?.sms || ""}
            disabled
            className={classes.textField}
            margin="normal"
            variant="outlined"
          />

          <FormControl disabled fullWidth className={classes.textField}>
            <InputLabel>通知邮箱</InputLabel>
            <Input value={previewContent?.notice_email || ""} />
          </FormControl>
          <FormControl disabled fullWidth className={classes.textField}>
            <InputLabel>通知手机号</InputLabel>
            <Input value={previewContent?.notice_mobile || ""} />
          </FormControl>
          <FormControl fullWidth className={classes.textField}>
            <InputLabel>测试邮箱</InputLabel>
            <Input
              value={testContact?.test_email || ""}
              onChange={changeTestContact("test_email")}
            />
          </FormControl>
          <FormControl fullWidth className={classes.textField}>
            <InputLabel>测试手机号</InputLabel>
            <Input
              value={testContact?.test_mobile || ""}
              onChange={changeTestContact("test_mobile")}
            />
          </FormControl>
          <FormControlLabel
            className={classes.textField}
            control={
              <Checkbox
                checked={testContact?.useContact}
                name="useContact"
                onChange={changeChecked}
                color="primary"
              />
            }
            label="发送给他时，也使用上述测试手机号"
          />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Tooltip title="发送给他">
          <IconButton onClick={handleSend(testContact)} size="medium">
            <Send fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="测试，发给我">
          <IconButton onClick={handleTest(testContact)} size="medium">
            <AddToHomeScreen fontSize="large" />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleDialogClose} size="small">
          Close
        </IconButton>
      </DialogActions>
    </Dialog>
  )
}

export default ClientPreview
