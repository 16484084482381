import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import TemplateList from "./TemplateList"
import TemplateEdit from "./TemplateEdit"

import { apiGet } from "lib/api-call"

import { endpoints } from "lib/end-points"

const Template = ({ setUsedTemplate }) => {
  const [template, setTemplate] = useState({
    name: "",
    email: "",
    sms: "",
    subject: "",
    id: null,
    send_email: true,
    send_sms: true,
    sms_api_key_ad: 0,
    use_n_contact: true
  })

  const [templates, setTemplates] = useState([])
  const refreshTemplate = () => {
    apiGet(endpoints.notice_template).then((res) => {
      if (res?.length) {
        setTemplates(res)
      }
    })
  }

  useEffect(() => {
      refreshTemplate()
  }, [])

  return (
    <>
      <Grid xs={6} item>
        <TemplateList
          setTemplate={setTemplate}
          templates={templates}
          setTemplates={setTemplates}
          refreshTemplate={refreshTemplate}
        />
      </Grid>
      <Grid xs={6} item>
        <TemplateEdit
          template={template}
          setTemplate={setTemplate}
          setUsedTemplate={setUsedTemplate}
          refreshTemplate={refreshTemplate}
        />
      </Grid>
    </>
  )
}

export default Template
