import { LOGIN_REQUESTING, LOGIN_OPENID } from './constants'

// In order to perform an action of type LOGIN_REQUESTING
// we need an email and password
export const loginRequest = function loginRequest(email, password, openid) {
  return {
    type: LOGIN_REQUESTING,
    email,
    password,
    openid
  }
}

export const loginOpenid = (token, openid) => {
  return {
    type: LOGIN_OPENID,
    token,
    openid
  }
} 
