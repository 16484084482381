import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

export default class ErrorBoundaryDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
  }

  handleClose = () => {
    this.setState({ open: false })
    window.location.reload()
  }

  render() {
    // const { error, info } = this.props
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          We encountered an error
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            <a href="/" style={{ textDecoration: "none" }}>
              <Button style={{ height: 40, backgroundColor: "#005bbd" }}>
                Back to Dashboard
              </Button>
            </a>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            We hit a problem. Try navigating from the left menu, if this issue
            persists please contact us directly via email: auwhver@gmail.com.
            {/* <br />
            The error was: {error.toString()}
            <br />
            It occured: {info.componentStack} */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Retry it
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
