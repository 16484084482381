import React, { useState } from "react"

import { apiPut, apiPost } from "lib/api-call"
import {
  Paper,
  Button,
  CircularProgress,
  Checkbox,
  TextField,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { endpoints } from "lib/end-points"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  textField: {
    margin: theme.spacing(1),
  },
  buttondiv: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}))

const TemplateEdit = ({
  template,
  setTemplate,
  setUsedTemplate,
  refreshTemplate,
}) => {
  const [updateLoading, setUpdateLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const classes = useStyles()

  const changeValue = (name) => (e) => {
    console.log(name, e)
    if (
      name === "send_email" ||
      name === "send_sms" ||
      name === "use_n_contact"
    ) {
      setTemplate({
        ...template,
        [name]: e.target.checked,
      })
    } else {
      setTemplate({
        ...template,
        [name]: e.target.value,
      })
    }
  }

  const createOrUpdate = (update = false) => () => {
    setTemplate(template)
    if (update) {
      setUpdateLoading(true)
      apiPut(`${endpoints.notice_template}${template.id}/`, template).then(
        (res) => {
          refreshTemplate()
          setUpdateLoading(false)
          setTemplate(res)
        }
      )
    } else {
      setCreateLoading(true)
      apiPost(endpoints.notice_template, template).then((res) => {
        setCreateLoading(false)
        refreshTemplate()
        setTemplate(res)
      })
    }
  }

  return (
    <Paper className={classes.root}>
      <TextField
        label="Name"
        fullWidth
        className={classes.textField}
        value={template?.name}
        onChange={changeValue("name")}
        margin="normal"
        variant="outlined"
        helperText="只是方便区分"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={template?.send_email || false}
            onChange={changeValue("send_email")}
            name="send_email"
            color="primary"
          />
        }
        label="发邮件"
      />
      <TextField
        label="Subject"
        fullWidth
        value={template?.subject}
        onChange={changeValue("subject")}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        helperText="参数替代格式<<<taobaoid>>>, 参数选项: country,taobaoid,family_name,given_name,gender,passport,notice_mobile,notice_email,customer_mobile,customer_email,is_this_time,is_credible,is_get,is_wget,birthday,passport_expire,wantday,url"
      />
      <TextField
        label="Email"
        multiline
        rows="8"
        fullWidth
        value={template?.email}
        onChange={changeValue("email")}
        className={classes.textField}
        margin="normal"
        variant="outlined"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={template?.send_sms || false}
            name="send_sms"
            color="primary"
            onChange={changeValue("send_sms")}
          />
        }
        label="发短信"
      />
      <FormControl className={classes.formControl} fullWidth style={{marginTop: 10, marginBottom: 10}}>
        <InputLabel>使用API Key</InputLabel>
        <Select
          value={template?.sms_api_key_ad}
          onChange={changeValue("sms_api_key_ad")}
        >
          <MenuItem value={0}>澳打君</MenuItem>
          <MenuItem value={1}>营销</MenuItem>
          <MenuItem value={2}>主账号</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="短信"
        multiline
        rows="4"
        fullWidth
        value={template?.sms}
        onChange={changeValue("sms")}
        className={classes.textField}
        margin="normal"
        variant="outlined"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={template?.use_n_contact || false}
            name="use_n_contact"
            color="primary"
            onChange={changeValue("use_n_contact")}
          />
        }
        label="没有c email/c mobile时候，使用n email/n mobile"
      />
      <div className={classes.buttondiv}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setUsedTemplate(template)}
        >
          使用该template
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={createOrUpdate(false)}
        >
          {createLoading ? <CircularProgress /> : "创建"}
        </Button>
        <Button
          variant="contained"
          onClick={createOrUpdate(true)}
          disabled={!template?.id}
        >
          {updateLoading ? <CircularProgress /> : "更新"}
        </Button>
      </div>
    </Paper>
  )
}

export default TemplateEdit
