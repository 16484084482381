import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import CirclePost from "../Map/QueryItem/Circle"
import Work from "../Map/QueryItem/Work"
import Event from "../Map/QueryItem/Event"
import Market from "../Map/QueryItem/Market"
import classNames from "classnames"
import { Button, CircularProgress } from "@material-ui/core"
import { isMobile } from "react-device-detect"
import CircleCreate from "../Map/CreateItem/Circle"
import WorkCreate from "../Map/CreateItem/Work"
import MarketCreate from "../Map/CreateItem/Market"
import { useIntl } from "react-intl"
import { defaultGPS } from "lib/constants"

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    backgroundColor: "white",
    width: "100%",
    padding: 10,
    marginBottom: 80,
  },
  root: {
    marginTop: 5,
    width: "100%",
  },
  show: {
    display: "block",
  },
  notShow: {
    display: "none",
  },
  createCard: {
    backgroundColor: "white",
    marginBottom: 10,
    padding: 16,
  },
}))

const ItemList = (props) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const [locationGPS, setLocationGPS] = useState(defaultGPS)
  const posts = props?.posts
  const cate = props?.cate
  const show = props?.show
  const hasMore = props?.hasMore
  const loading = props?.loading
  const loadMorePost = props?.loadMorePost

  useEffect(() => {
    const getMyLocation = () => {
      const location = window.navigator && window.navigator.geolocation
      if (location) {
        location.getCurrentPosition(
          (position) => {
            setLocationGPS({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          },
          (error) => console.log(error)
        )
      }
    }
    getMyLocation()
  }, [])

  return (
    <div
      className={classNames(
        classes.root,
        show ? [classes.show] : [classes.notShow]
      )}
    >
      {cate === "circle" && (
        <div className={classes.createCard}>
          <CircleCreate
            selectedPoint={locationGPS}
            setSelectedPoint={() => {}}
            setRefreshLatest={loadMorePost("circle", true)}
            isList
          />
        </div>
      )}
      {cate === "market" && (
        <div className={classes.createCard}>
          <MarketCreate
            selectedPoint={locationGPS}
            setSelectedPoint={() => {}}
            setRefreshLatest={loadMorePost("market", true)}
            isList
          />
        </div>
      )}
      {cate === "work" && (
        <div className={classes.createCard}>
          <WorkCreate
            selectedPoint={locationGPS}
            setSelectedPoint={() => {}}
            setRefreshLatest={loadMorePost("work", true)}
            isList
          />
        </div>
      )}
      {cate === "circle" &&
        posts.map((post) => <CirclePost key={post.id} listItem={post} />)}
      {cate === "work" &&
        posts.map((post) => <Work key={post.id} listItem={post} />)}
      {cate === "event" &&
        posts.map((post) => <Event key={post.id} listItem={post} />)}
      {cate === "market" &&
        posts.map((post) => <Market key={post.id} listItem={post} />)}
      {hasMore && (
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={loadMorePost(cate)}
        >
          {loading && <CircularProgress />}
          {f({ id: "loadmore" })}
        </Button>
      )}
      {isMobile && <div style={{ height: 300 }} />}
    </div>
  )
}

export default ItemList
