import AuthSaga from "views/Auth/sagas"
import LoginSaga from "views/Auth/Login/sagas"
// import PasswordSaga from "views/Auth/Password/sagas"
// import Alerts from "components/Alerts/sagas"
// import userProfile from "views/UI/Settings/User/Profile/sagas"
import { all } from "redux-saga/effects"

export default function* IndexSaga() {
  yield all([AuthSaga(), LoginSaga()])
}
