import React, { useState } from "react"

import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Delete } from "@material-ui/icons"
import moment from "moment"
import CommentContent from "./CommentContent"
import ReplyTo from "./ReplyTo"
import { Comment as CommentCom } from "antd"

import DeleteDialog from "components/DeleteDialog/index"

const useStyles = makeStyles((theme) => ({
  comment: {
    paddingTop: 2,
    paddingBottom: 2,
  },
}))

const CustomCommentCom = (props) => {
  const classes = useStyles()
  const cate = props?.cate
  const comment = props?.comment
  const replyClick = props?.replyClick
  const showDeleteButton = props?.showDeleteButton || false
  const [showDelete, setShowDelete] = useState(false)
  const [deleteContent, setDeleteContent] = useState(null)
  const [deleted, setDeleted] = useState(false)
  const deleteClick = (id) => () => {
    setShowDelete(true)
    setDeleteContent(id)
  }

  const handleCloseDelete = (deleted = false) => () => {
    setShowDelete(false)
    if (deleted) {
      setDeleted(true)
    }
  }

  const DeleteButton = () => {
    return (
      <>
        {showDeleteButton ? (
          <Button onClick={deleteClick(comment.id)} variant="contained">
            <Delete />
          </Button>
        ) : null}
      </>
    )
  }
  return (
    <>
      {deleted ? null : (
        <>
          <DeleteDialog
            cate={cate}
            itemId={deleteContent}
            handleClose={handleCloseDelete}
            open={showDelete}
            isComment
          />
          <CommentCom
            key={comment.id}
            actions={[
              <ReplyTo
                id={comment.id}
                commentter={comment?.user}
                replyClick={replyClick}
              />,
              <DeleteButton />,
            ]}
            author={comment?.user?.profile?.nickname}
            avatar={comment?.user?.profile.avatarUrl}
            content={<CommentContent comment={comment} />}
            datetime={moment(comment.created_on).format(
              "MMM Do YYYY, h:mm:ss a"
            )}
            className={classes.comment}
          />
        </>
      )}
    </>
  )
}


export default CustomCommentCom