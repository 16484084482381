import React from "react"
import { useSelector } from "react-redux"
import User from "./User"
import { Link } from "react-router-dom"
// import Notifications from "./Notifications/Notifications"
import { useDispatch } from "react-redux"
import FeedbackIcon from "./FeedbackIcon"
import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Switch,
  FormControlLabel,
} from "@material-ui/core"
import { Help as HelpIcon } from "@material-ui/icons"
import { useIntl } from "react-intl"
import { isMobile } from "react-device-detect"

export default ({ menuContext, rightMenuState, rightMenuToggle, auth }) => {
  const loggedIn = useSelector((state) => state.auth.user.loggedIn)
  const en = useSelector((state) => state.auth.user.initEn)
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()
  const handleTour = () => {
    dispatch({ type: "USER_PROFILE_SHOW_TOUR" })
  }

  const handleEn = () => {
    dispatch({ type: "USER_CHANGE_EN" })
  }
  return (
    <>
      {loggedIn && (
        <>
          <FeedbackIcon />
          {/* <Notifications auth={auth} /> */}
          <User />
        </>
      )}
      {!loggedIn && (
        <Link to="/auth/login/">
          <Button
            variant="contained"
            color="secondary"
            size={isMobile ? "small" : "large"}
            style={{ marginRight: 5, textDecoration: "underline" }}
          >
            <Typography variant="body1">{f({ id: "login" })}</Typography>
          </Button>
        </Link>
      )}
      <Tooltip title={f({ id: "switchEn" })}>
        <FormControlLabel
          control={<Switch checked={en} onChange={handleEn} name="en" />}
          label={en ? "EN" : "CN"}
          style={{
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 5,
            marginRight: 5,
          }}
        />
      </Tooltip>
      <Tooltip title={f({ id: "guide" })}>
        <IconButton color="inherit" onClick={handleTour}>
          <HelpIcon fontSize={isMobile ? "medium" : "large"} />
        </IconButton>
      </Tooltip>
    </>
  )
}
