import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core"
import {
  countryCode,
  encountryCode,
  auStateChoices,
  nzStateChoices,
  stateChoices,
  enstateChoices,
  marketCates,
  enmarketCates,
  qiuCode,
  enqiuCode,
} from "lib/constants"
import ImageUpload from "./MarketImageUpload"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    width: "100%",
  },
  selectTable: {
    width: "100%",
    color: "grey",
    marginTop: 10,
    marginBottom: 10,
  },
  formControl: {
    width: "100%",
    marginTop: 5,
  },
  checkFormControl: {
    display: "flex",
    justifyContent: "space-around",
  },
  button: {
    "&:focus, &:hover, &$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const MarketCreate = (props) => {
  const { formatMessage: f } = useIntl()
  const en = useSelector((state) => state.auth.user.initEn)
  const selectedPoint = props?.selectedPoint
  const setRefreshLatest = props?.setRefreshLatest
  const setSelectedPoint = props?.setSelectedPoint
  const [posted, setPosted] = useState(false)
  const [desc, setDesc] = useState("")
  const [qiuChu, setQiuChu] = useState("q")
  const [country, setCountry] = useState("AU")
  const [state, setState] = useState("VIC")
  const [category, setCategory] = useState("ZT")

  const [contact, setContact] = useState("")
  const [location, setLocation] = useState("")
  const selectChange = (kind) => (e) => {
    if (kind === "country") {
      setCountry(e.target.value)
      setState(e.target.value === "AU" ? "VIC" : "BD")
    } else if (kind === "qiu") {
      setQiuChu(e.target.value)
    } else if (kind === "state") {
      setState(e.target.value)
    } else if (kind === "category") {
      setCategory(e.target.value)
    }
    setPosted(false)
  }

  const changeDesc = (e) => {
    setDesc(e.target.value)
    setPosted(false)
  }

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl}>
        <TextField
          required
          fullWidth
          label={f({ id: "content" })}
          multiline
          rows={4}
          value={desc}
          placeholder={f({ id: "content" })}
          variant="outlined"
          onChange={changeDesc}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "sell-buy" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={qiuChu}
          onChange={selectChange("qiu")}
        >
          {Object.keys(qiuCode).map((key) => (
            <MenuItem key={key} value={key}>
              {en ? enqiuCode[key] : qiuCode[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "country" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={country}
          onChange={selectChange("country")}
        >
          {Object.keys(countryCode).map((key) => (
            <MenuItem key={key} value={key}>
              {en ? encountryCode[key] : countryCode[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "state" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={state}
          onChange={selectChange("state")}
        >
          {Object.keys(country === "AU" ? auStateChoices : nzStateChoices).map(
            (key) => (
              <MenuItem key={key} value={key}>
                {en ? enstateChoices[key] : stateChoices[key]}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">
          {f({ id: "category" })}
        </InputLabel>
        <Select
          className={classes.selectTable}
          value={category}
          onChange={selectChange("category")}
        >
          {Object.keys(marketCates).map((key) => (
            <MenuItem key={key} value={key}>
              {en ? enmarketCates[key] : marketCates[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label={f({ id: "contact" })}
          value={contact}
          onChange={(e) => {
            setContact(e.target.value)
            setPosted(false)
          }}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label={f({ id: "address" })}
          value={location}
          onChange={(e) => {
            setLocation(e.target.value)
            setPosted(false)
          }}
        />
      </FormControl>
      <ImageUpload
        desc={desc}
        qiuChu={qiuChu}
        country={country}
        state={state}
        category={category}
        contact={contact}
        location={location}
        posted={posted}
        setPosted={setPosted}
        selectedPoint={selectedPoint}
        setSelectedPoint={setSelectedPoint}
        setRefreshLatest={setRefreshLatest}
      />
    </div>
  )
}

export default MarketCreate
