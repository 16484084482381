import React from "react"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { apiDelete } from "lib/api-call"
import { Paper, IconButton } from "@material-ui/core"
import { endpoints } from "lib/end-points"
import { Delete, Refresh, Edit } from "@material-ui/icons"
import moment from "moment"

const TemplateList = ({
  setTemplate,
  refreshTemplate,
  templates,
  setTemplates,
}) => {


  const deleteTemplate = (id) => () => {
    apiDelete(`${endpoints.notice_template}${id}/`).then((res) => {
      setTemplates(templates.filter((x) => x.id !== id))
    })
  }

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: "name", // String-based value accessors!
      filterable: true,
    },
    {
      Header: "Send SMS",
      accessor: "send_sms",
      Cell: (props) => (
        props.original?.send_sms ? "是": "否" 
      ),
    },
    {
      Header: "SMS",
      accessor: "sms",
      filterable: true,
    },
    {
      Header: "Send Email",
      accessor: "send_email",
      Cell: (props) => (
        props.original?.send_email ? "是": "否" 
      ),
    },
    {
      Header: "Subject",
      accessor: "subject",
      filterable: true,
    },

    {
      Header: "Email",
      accessor: "email",
      filterable: true,
    },
    {
      Header: "Use N contact",
      accessor: "use_n_contact",
      Cell: (props) => (
        props.original?.use_n_contact ? "是": "否"
      ),
    },
    {
      Header: "UpdatedAt",
      id: "updatedat",
      accessor: (d) => moment(d.updated_at).format("MM-DD HH"),
    },

    {
      Header: "Edit",
      Cell: (props) => (
        <IconButton onClick={() => setTemplate(props.original)} size="small">
          <Edit fontSize="small" />
        </IconButton>
      ),
      sortable: false,
    },
    {
      Header: "Delete",
      Cell: (props) => (
        <IconButton onClick={deleteTemplate(props.original.id)} size="small">
          <Delete fontSize="small" />
        </IconButton>
      ),
      sortable: false,
    },
    
  ]

  return (
    <Paper style={{height: "100%"}}>
      <IconButton onClick={refreshTemplate}>
        <Refresh />
      </IconButton>
      <ReactTable
        data={templates}
        columns={columns}
        style={{ textAlign: "center", maxHeight: 1000 }}
        defaultPageSize={10}
      />
    </Paper>
  )
}

export default TemplateList
