import {
  USER_SET,
  USER_SET_PROFILE,
  USER_LOGOUT,
  USER_PROFILE_REQUEST,
  USER_PROFILE_SHOW_TOUR,
  USER_PROFILE_SET_SHOW_TOUR,
  USER_SHOW_FEEDBACK_FORM
} from "./constants"

export const userSet = token => ({
  type: USER_SET,
  token
})

export const userSetProfile = profile => ({
  type: USER_SET_PROFILE,
  profile
})

export const userProfileRequest = () => ({
  type: USER_PROFILE_REQUEST
})

export const userLogout = () => ({
  type: USER_LOGOUT
})

export const userShowTour = () => ({
  type: USER_PROFILE_SHOW_TOUR
})

export const userSetShowTour = shown => ({
  type: USER_PROFILE_SET_SHOW_TOUR,
  shown
})

export const userShowFeedbackForm = () => ({
  type: USER_SHOW_FEEDBACK_FORM
})
