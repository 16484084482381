// Functionality is grouped into sections and the composed/ordered for each group at the end.

const dashLinks = [
  { type: "subHeader", i18nId: "mainNav-analysis" },
  {
    type: "listItem",
    i18nId: "map",
    icon: "DashboardIcon",
    link: "/dashboard/map/",
  },
  {
    type: "listItem",
    i18nId: "list",
    icon: "ListIcon",
    link: "/dashboard/list/",
  },
  {
    type: "listItem",
    i18nId: "mine",
    icon: "Person",
    link: "/dashboard/mine/",
  },
]

const navLinksOrgUser = [...dashLinks]

export default function getLinks(group) {
  return navLinksOrgUser
}
