import React from "react"

import { connect } from "react-redux"
import { leftMenuToggle } from "./actions"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import NavList from "./NavList"

const drawerWidth = 240

const styles = theme => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    backgroundColor: "#011d33"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  chevronIcon: {
    color: "#768591"
  }
})

function LeftMenu({ currentUrl, leftMenuToggle, classes, leftMenuState }) {
  const open = leftMenuState
  return (
    <Drawer
      variant="persistent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
      open={open}
    >
      <div className={classes.drawerInner}>
        <div className={classes.drawerHeader}>
          <IconButton onClick={() => leftMenuToggle()}>
            <ChevronLeftIcon classes={{ root: classes.chevronIcon }} />
          </IconButton>
        </div>
        <NavList currentUrl={currentUrl} />
      </div>
    </Drawer>
  )
}

const mapStateToProps = state => ({
  leftMenuState: state.ui.leftNav.leftToggle
})

const connected = connect(mapStateToProps, { leftMenuToggle })(LeftMenu)

export default withStyles(styles)(connected)
