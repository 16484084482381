const common = {
  signOut: "退出登陆",
  profile: "个人主页",
  mapmode: "地图模式",
  listmode: "列表模式",
  minemode: "个人列表",
  "help-feedbackTitle": "建议反馈，或者请求删除您的数据",
  "help-feedbackDesc": "填写您的反馈建议，或者请求删除您的数据，我们将在两个工作日内回复您。",
  "help-feedbackPlaceholder": "不管是程序bug，还是运营建议，抑或是信息情报，我们通通欢迎。如果您要删除您的数据，我们将在两个工作日内回复您。",
  "help-feedbackSuccess": "收到您的反馈建议或者请求，我们会尽快处理，感谢",
  "help-feedbackError": "好像出错了，再试一下吧",
  "close": "关闭",
  "send": "发送",
  "mainNav-analysis": "主菜单",
  "map": "地图模式",
  "list": "列表模式",
  "mine": "个人列表",
  "switchEn": "切换语言",
  "guide": "点击展示应用导览",
  "login": "登陆",
  "sharetof": "点击复制分享给好友",
  "copytoclip": "已复制到剪贴板",
  "copy": "复制",
  "yourthought": "这一刻的想法",
  "farmdesc": "介绍并描述下该农场",
  "farmplaceholder": "联系方式，网站地址，用工季节，具体地址等一些关于该农场的基本介绍信息；我们收到回报后会进行核实，并添加到地图上。感谢您的帮助。",
  "contact": "联系方式",
  "address": "地址",
  "error-hint": "错误，重试",
  "thank-your-post": "感谢您的提交发布",
  "post": "发布",
  "circle": "朋友圈",
  "work": "工作",
  "farm": "农场信息",
  "market": "市集信息",
  "content": "帖子内容",
  "sell-buy": "求/出",
  "country": "国家",
  "category": "信息分类",
  "workDesc": "工作内容描述",
  "state": "州",
  "inducate": "行业分类",
  "paytax": "打税",
  "secondyear": "二签",
  "salary": "工资",
  "h": "按小时",
  "c": "计件",
  "creator": "发布者",
  'yes': "是",
  "no": "否",
  "contactbyyou": "自行联系确认",
  "fromnet": "网络情报",
  "notlogin": "您还未登陆，请登陆后查看",
  "loginclick": "点击登陆",
  "fromshare": "来自分享:",
  "notfoundmap": "你寻找的分享内容不见咯，到地图上探寻点别的有意思的吧～",
  "loadmore": "载入更多...",
  "thismonth": "本月",
  "showsatellite": "显示卫星视图",
  "shownorth": "显示南回归线以北二签区域",
  "showsa": "显示偏远地区二签区域（仅允许从事农牧业二签）",
  "showsb": "显示森林大火影响的二签区域",
  "harvestmap": "Harvest Map, 根据月份收获不同",
  "farmlist": "农场清单",
  "showmixedlayer": "筛选圈子、工作、市集、活动",
  "hidemixedlayer": "隐藏筛选圈子、工作、市集、活动",
  "hideallmixedlayer": "隐藏所有的圈子、工作、市集、活动信息",
  "showrecent7": "显示最近七天的圈子、工作、市集、活动信息",
  "showbasedm": "按月份显示圈子、工作、市集、活动等信息",
  "showall": "显示历史所有圈子、工作、市集、活动的信息",
  "event": "活动",
  "postinfo": "发布圈子/工作，或汇报农场信息",
  "minepost": "我发布的",
  "minecomment": "我评论的",
  "minesave": "我收藏的",
  "nothinghere": "这里没有任何东西哦."
}

const loginView = {
  "loginAsGuest": "游客模式访问",
  "scanned": "已扫码",
  "scanWithWechat": "请使用微信扫描二维码登陆",
  "iwhvername": "@打工度假圈儿"
}


export default {
  ...common,
  ...loginView
}
