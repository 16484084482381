/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

import enBgTranslationMessages from "./translations/en"
import zhTranslationMessages from "./translations/zh"

export const appLocales = ["en", "zh"]

export const translationMessages = {
  "en": enBgTranslationMessages,
  "zh": zhTranslationMessages
}
