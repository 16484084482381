import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import CirclePost from "../Map/QueryItem/Circle"
import Work from "../Map/QueryItem/Work"
import Event from "../Map/QueryItem/Event"
import Market from "../Map/QueryItem/Market"
import classNames from "classnames"
import { CircularProgress, Typography } from "@material-ui/core"
import { isMobile } from "react-device-detect"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  button: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    backgroundColor: "white",
    width: "100%",
    padding: 10,
    marginBottom: 80,
  },
  root: {
    marginTop: 5,
    width: "100%",
  },
  show: {
    display: "block",
  },
  notShow: {
    display: "none",
  },
  createCard: {
    backgroundColor: "white",
    marginBottom: 10,
    padding: 16,
  },
  centerDiv: {
    margin: "auto",
    width: "100%",
    height: "100%",
    display: "flex",
  },
  centerEle: {
    margin: "auto",
  },
}))

const ItemList = (props) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const posts = props?.posts
  const show = props?.show
  const load = props?.load

  return (
    <div
      className={classNames(
        classes.root,
        show ? [classes.show] : [classes.notShow]
      )}
    >
      {posts.length === 0 && load && (
        <div className={classes.centerDiv}>
          <CircularProgress className={classes.centerEle} />
        </div>
      )}
      {posts.length === 0 && !load && (
        <div className={classes.centerDiv}>
          <Typography className={classes.centerEle}>
            {f({id: "nothinghere"})}
          </Typography>
        </div>
      )}
      {posts.map((post) => {
        if (post.name === "circle") {
          return <CirclePost key={`circle-${post.id}`} listItem={post} />
        }
        if (post.name === "work") {
          return <Work key={`work-${post.id}`} listItem={post} />
        }
        if (post.name === "event") {
          return <Event key={`event-${post.id}`} listItem={post} />
        }
        if (post.name === "market") {
          return <Market key={`market-${post.id}`} listItem={post} />
        }
        return null
      })}

      {isMobile && <div style={{ height: 300 }} />}
    </div>
  )
}

export default ItemList
