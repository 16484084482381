import { RIGHT_MENU_TOGGLE } from "./constants"

const initialState = {
  rightToggle: false,
  context: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RIGHT_MENU_TOGGLE:
      return {
        ...state,
        rightToggle: !state.rightToggle
      }

    default:
      return state
  }
}

export default reducer
