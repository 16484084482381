import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Select, MenuItem, Typography, Grid } from "@material-ui/core"

import CircleCreate from "./Circle"
import WorkCreate from "./Work"
import FarmCreate from "./Farm"
import MarketCreate from "./Market"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  gridContainer: {},
  gridItem: {
    // padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: 5,
    padding: 5,
    paddingBottom: 15,
  },
  selectContainer: {
    width: "100%",
  },
  selectLabel: {
    color: "grey",
  },
  selectTable: {
    width: "100%",
    color: "grey",
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    color: "grey",
    marginTop: 10,
    marginBottom: 10,
  },
}))

const CreateItem = (props) => {
  const { formatMessage: f } = useIntl()
  const selectedPoint = props?.selectedPoint
  const setRefreshLatest = props?.setRefreshLatest
  const setSelectedPoint = props?.setSelectedPoint
  const classes = useStyles()
  const [postType, setPostType] = useState("circle")
  const selectChange = (e) => {
    setPostType(e.target.value)
  }
  return (
    <Grid container className={classes.gridContainer}>
      <Grid item xs={12} className={classes.gridItem}>
        <div className={classes.selectContainer}>
          <Typography variant="h5" className={classes.title}>
            {f({ id: "post" })}
          </Typography>
          <Select
            className={classes.selectTable}
            value={postType}
            onChange={selectChange}
          >
            <MenuItem value="circle">{f({ id: "circle" })}</MenuItem>
            <MenuItem value="work">{f({ id: "work" })}</MenuItem>
            <MenuItem value="farm">{f({ id: "farm" })}</MenuItem>
            <MenuItem value="market">{f({ id: "market" })}</MenuItem>
          </Select>
        </div>
        {postType === "circle" && (
          <CircleCreate
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
            setRefreshLatest={setRefreshLatest}
          />
        )}
        {postType === "work" && (
          <WorkCreate
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
            setRefreshLatest={setRefreshLatest}
          />
        )}
        {postType === "farm" && (
          <FarmCreate
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
            setRefreshLatest={setRefreshLatest}
          />
        )}
        {postType === "market" && (
          <MarketCreate
            selectedPoint={selectedPoint}
            setSelectedPoint={setSelectedPoint}
            setRefreshLatest={setRefreshLatest}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default CreateItem
