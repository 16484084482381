import React  from "react"
import { Typography, Paper, Toolbar } from "@material-ui/core"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"

const ClientStatus = ({ sendingResult }) => {
  // let reactTable = useRef(null)
  const fillColor = (field) => (state, rowInfo, column) => {
    return {
      style: {
        backgroundColor:
          rowInfo && rowInfo.original[field] ? "green" : null,
      },
    }
  }
  const columns = [
    {
      Header: "taobaoid",
      accessor: "taobaoid",
      filterable: true,
    },
    {
      Header: "email",
      accessor: (d) => d.email,
      id: "email",
      Cell: (props) => <span>{props.value ? "是" : "否"}</span>,
      getProps: fillColor("email"),
    },
    {
      Header: "sms",
      accessor: (d) => d.sms,
      id: "sms",
      Cell: (props) => <span>{props.value ? "是" : "否"}</span>,
      getProps: fillColor("sms"),
    },
    {
      Header: "to email",
      accessor: "notice_email",
    },
    {
      Header: "to mobile",
      accessor: "notice_mobile",
    },
    {
      Header: "sms reason",
      accessor: "sms_reason"
    }
  ]
  return (
    <Paper style={{height: "100%"}}>
      <Toolbar style={{ width: "100%" }}>
          <Typography variant="h4" style={{ color: "red", flex: 1 }}>
            发送结果({sendingResult?.results?.length}): {sendingResult.status}
          </Typography>
        </Toolbar>
      <ReactTable
        // ref={(r) => (reactTable = r)}
        data={sendingResult.results}
        columns={columns}
        defaultPageSize={200}
        style={{ textAlign: "center", maxHeight: 250 }}
      />
    </Paper>
  )
}

export default ClientStatus
