import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import Header from "components/Header/"

import LeftMenu from "components/SideNavLeft/LeftMenu"
import { userLogout } from "views/Auth/Profile/actions"
import UiRoutes from "./UiRoutes"

const drawerWidth = 240

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    marginTop: 0,
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "calc(100% - 64px)",
    overflow: "auto",
    marginTop: 64,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 64px)",
      marginTop: 64,
    },
  },
  "content-left": {
    marginLeft: -drawerWidth,
  },
  "content-right": {
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "contentShift-left": {
    marginLeft: 0,
  },
})

const UI = (props) => {
  const { classes, match, location } = props
  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <Header />
        <LeftMenu
          currentUrl={props.history.location.pathname}
          logout={props.userLogout}
        />
        <main className={classNames(classes.content, classes[`content-left`])}>
          <UiRoutes match={match} location={location} />
        </main>
      </div>
    </div>
  )
}

UI.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  leftMenuState: state.ui.leftNav.leftToggle,
})

const StyledComponent = withStyles(styles, { withTheme: true })(UI)

const connected = connect(mapStateToProps, { userLogout })(StyledComponent)

export default connected
