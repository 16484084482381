import React, { useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Tooltip,
} from "@material-ui/core"
import { appEndpoint } from "lib/constants"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useIntl } from "react-intl"


const ShareDialog = (props) => {
  const cate = props?.cate
  const itemId = props?.itemId
  const open = props?.open
  const handleClose = props?.handleClose
  const { formatMessage: f } = useIntl()
  const shareUrl = `${appEndpoint}/#/dashboard/map?cate=${cate}&id=${itemId}`
  const [copied, setCopied] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogContent>
        <CopyToClipboard text={shareUrl}>
          <TextField
            id="standard-full-width"
            placeholder={shareUrl}
            helperText={f({id: "sharetof"})}
            style={{ width: 420, margin: 8 }}
            margin="normal"
            value={shareUrl}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            onClick={() => setCopied(true)}
          />
        </CopyToClipboard>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          {f({id: "close"})}
        </Button>
        <Tooltip
          open={copied}
          title={f({id: "copytoclip"})}
          leaveDelay={1500}
          onClose={() => setCopied(false)}
        >
          <CopyToClipboard text={shareUrl}>
            <Button autoFocus onClick={() => setCopied(true)} color="primary">
              {f({id: "copy"})}
            </Button>
          </CopyToClipboard>
        </Tooltip>
      </DialogActions>
    </Dialog>
  )
}

export default ShareDialog
