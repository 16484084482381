import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import Event from "./QueryItem/Event"
import CirclePost from "./QueryItem/Circle"
import Market from "./QueryItem/Market"
import Work from "./QueryItem/Work"
import CreateItem from "./CreateItem/index"
import { Grid, IconButton, Paper } from "@material-ui/core"
import { Close } from "@material-ui/icons"
import { endpoints } from "lib/end-points"
import { apiGet } from "lib/api-call"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core"
import { isMobile } from "react-device-detect"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 10,
    right: 0,
    width: "350px",
    height: "100%",
    backgroundColor: "grey",
    overflow: "auto",
  },
  rootMobile: {
    position: "fixed",
    zIndex: 10,
    bottom: 0,
    height: "calc(50vh - 64px)",
    width: "100%",
    backgroundColor: "grey",
    overflow: "auto",
  },
  gridContainer: {
    background: "grey",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  closeButton: {
    position: "absolute",
    top: 120,
    right: 350,
    backgroundColor: "white",
    zIndex: 30,
    "&:focus, &:hover, &$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  closeButtonMobile: {
    position: "absolute",
    bottom: "calc(50vh - 64px)",
    left: 0,
    backgroundColor: "white",
    zIndex: 30,
    "&:focus, &:hover, &$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  card: {
    paddingTop: theme.spacing(1),
  },
  button: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    backgroundColor: "white",
    width: "100%",
    padding: 10,
  },
  askLoginPaper: {
    minHeight: 200,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  askButton: {
    marginTop: 10,
    textDecoration: "underline"
  }
}))

const AskLogin = () => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  return (
    <Paper className={classes.askLoginPaper}>
      <Typography variant="h6">
        {f({id: "notlogin"})}
      </Typography>
      <Link to="/auth/login/">
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: 5 }}
          className={classes.askButton}
        >
          <Typography variant="body1">{f({id: "loginclick"})}</Typography>
        </Button>
      </Link>
    </Paper>
  )
}
const ItemsList = (props) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const loggedIn = useSelector((state) => state.auth.user.loggedIn)
  const cate = props?.cate
  const itemId = props?.itemId
  const history = props?.history
  const pathname = props?.pathname
  const flyTo = props?.flyTo
  const selectedFeatures = props?.selectedFeatures.sort((a, b) =>
    a.properties?.time < b.properties?.time ? 1 : -1
  )
  const setSelectedPoint = props?.setSelectedPoint
  const selectedPoint = props?.selectedPoint
  const setRefreshLatest = props?.setRefreshLatest
  const [shareItem, setShareItem] = useState(false)
  const [loading, setLoading] = useState(true)
  const [shareItemOpen, setShareItemOpen] = useState(
    cate && itemId ? true : false
  )

  const [items, setItems] = useState(selectedFeatures.slice(0, 20))
  const [itemIndex, setItemIndex] = useState(20)

  useEffect(() => {
    if (cate && itemId) {
      apiGet(`${endpoints.item}?cate=${cate}&id=${itemId}`).then((res) => {
        setLoading(false)
        if (res?.item) {
          setShareItem(res.item)
          setShareItemOpen(true)
          if (flyTo && res?.gps) flyTo(res.gps)
        }
      })
    }
  }, [cate, itemId, flyTo])

  useEffect(() => {
    setItems(selectedFeatures.slice(0, 20))
  }, [selectedFeatures])

  useEffect(() => {
    setItems(selectedFeatures.slice(0, itemIndex))
  }, [itemIndex, selectedFeatures])

  const handleDeselectActive = () => {
    const setFeatures = props?.setFeatures
    setFeatures([])
    setSelectedPoint({ lat: 0, lng: 0 })
  }

  const handleClose = () => {
    setShareItem(false)
    setShareItemOpen(false)
    if (history) {
      history.replace({
        pathname: pathname || "/dashboard/map",
        search: "",
      })
    }
    // clean query here
  }
  return (
    <>
      <Dialog
        open={shareItemOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <Typography variant="h5">{f({id: "fromshare"})}</Typography>
          {loading && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 300,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}
          {!loading && !shareItem && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: 300,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>
                {f({id: "notfoundmap"})}
              </Typography>
            </div>
          )}
          {cate === "work" && shareItem && <Work shareItem={shareItem} />}
          {cate === "circle" && shareItem && (
            <CirclePost shareItem={shareItem} />
          )}
          {cate === "event" && shareItem && <Event shareItem={shareItem} />}
          {cate === "market" && shareItem && <Market shareItem={shareItem} />}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {f({id: "close"})}
          </Button>
        </DialogActions>
      </Dialog>
      {selectedFeatures.length ||
      (selectedPoint.lat !== 0 && selectedPoint.lng !== 0) ? (
        <>
          <div
            className={
              isMobile ? classes.closeButtonMobile : classes.closeButton
            }
          >
            <IconButton onClick={handleDeselectActive} size="small">
              <Close />
            </IconButton>
          </div>
          <div className={isMobile ? classes.rootMobile : classes.root}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Grid
                  container
                  className={classes.gridContainer}
                  alignItems="flex-start"
                >
                  {!loggedIn ? (
                    <AskLogin />
                  ) : (
                    <>
                      {selectedFeatures &&
                        items.map((feature, index) => {
                          return (
                            <div key={index} className={classes.card}>
                              {feature.properties.type === "event" && (
                                <Event key={feature.id} item={feature} />
                              )}
                              {feature.properties.type === "circle" && (
                                <CirclePost key={feature.id} item={feature} />
                              )}
                              {feature.properties.type === "market" && (
                                <Market key={feature.id} item={feature} />
                              )}
                              {feature.properties.type === "work" && (
                                <Work key={feature.id} item={feature} />
                              )}
                            </div>
                          )
                        })}
                      {selectedFeatures && selectedFeatures.length > itemIndex && (
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                          onClick={() => setItemIndex(itemIndex + 10)}
                        >
                          {f({id: "loadmore"})}
                        </Button>
                      )}
                      {selectedPoint.lat !== 0 &&
                        selectedPoint.lng !== 0 &&
                        selectedFeatures.length === 0 && (
                          <CreateItem
                            selectedPoint={selectedPoint}
                            setSelectedPoint={setSelectedPoint}
                            setRefreshLatest={setRefreshLatest}
                          />
                        )}
                      <div
                        className={classes.card}
                        style={{ height: isMobile ? 100 : 200 }}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      ) : null}
    </>
  )
}

export default ItemsList
