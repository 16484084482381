import React, { useState } from "react"
import {
  Paper,
  Typography,
  IconButton,
  Toolbar,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
} from "@material-ui/core"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { Add } from "@material-ui/icons"
import { makeStyles } from "@material-ui/core/styles"
import CSVReader from "react-csv-reader"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
  },
  textField: {
    margin: theme.spacing(1),
  },
  buttondiv: {
    display: "flex",
    flexDirection: "row-reverse",
  },
}))
const ClientExtra = ({ extraContacts, setExtraContacts, showAdd }) => {
  // let reactTable = useRef(null)
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)
  const [contactStr, setContactStr] = useState("")
  const columns = [
    {
      Header: "email",
      accessor: "email",
      filterable: true,
    },
    {
      Header: "mobile",
      accessor: "mobile",
      filterable: true,
    },
  ]

  const addContacts = () => {
    const contacts = contactStr.split("\n").map((contact) => {
      const content = contact.split(",")
      if (content.length === 2) {
        return {
          email: content[0],
          mobile: content[1],
        }
      } else if (content.length === 1) {
        if (content[0].includes("@")) {
          return {
            email: content[0],
            mobile: "",
          }
        } else {
          return {
            email: "",
            mobile: content[0],
          }
        }
      }
      return null
    })
    setExtraContacts(contacts)
  }
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true
  }

  const handleFromCsv = data => {
    const contacts = data.map((contact) => {
      if (contact.length === 2) {
        return {
          email: contact[0],
          mobile: contact[1],
        }
      } else if (contact.length === 1) {
        if (contact[0].includes("@")) {
          return {
            email: contact[0],
            mobile: "",
          }
        } else {
          return {
            email: "",
            mobile: contact[0],
          }
        }
      }
      return null
    })
    setExtraContacts(contacts)
  }
  return (
    <>
      <Dialog onClose={() => setShowDialog(false)} open={showDialog} fullWidth>
        <DialogTitle> 添加附加收件人</DialogTitle>
        <DialogContent>
          <Paper className={classes.root}>
            <TextField
              label="联系人信息，可以从csv格式复制进去"
              placeholder="email,mobile"
              multiline
              fullWidth
              variant="filled"
              rows={10}
              value={contactStr}
              onChange={(e) => setContactStr(e.target.value)}
            />
          </Paper>
          <CSVReader
            papaparseOptions={papaparseOptions}
            onFileLoaded={handleFromCsv}
          />
        </DialogContent>
        <DialogActions>
          <Tooltip title="添加">
            <IconButton onClick={addContacts} size="medium" disabled={!showAdd}>
              <Add fontSize="large" />
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => setShowDialog(false)} size="small">
            Close
          </IconButton>
        </DialogActions>
      </Dialog>
      <Paper>
        <Toolbar style={{ width: "100%" }}>
          <Typography variant="h4" style={{ color: "red", flex: 1 }}>
            附加收件人{`(${extraContacts.length})`}{" "}
            {!showAdd && "模版中含参数,无法添加"}
          </Typography>
          <Tooltip
            title={`添加附加信息`}
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto" }}
          >
            <Fab
              size="large"
              color="primary"
              disabled={!showAdd}
              onClick={() => setShowDialog(true)}
              component="div"
            >
              <Add />
            </Fab>
          </Tooltip>
        </Toolbar>
        <ReactTable
          // ref={(r) => (reactTable = r)}
          data={extraContacts}
          columns={columns}
          defaultPageSize={200}
          style={{ textAlign: "center", maxHeight: 250 }}
        />
      </Paper>
    </>
  )
}

export default ClientExtra
