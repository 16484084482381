import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Divider,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Share, Favorite, Comment, Delete } from "@material-ui/icons"
import moment from "moment"
import Editor from "./Editor"
import { Badge  } from "antd"
import { endpoints } from "lib/end-points"
import { apiGet, apiPost } from "lib/api-call"
import ShareDialog from "components/ShareDialog/index"
import DeleteDialog from "components/DeleteDialog/index"
import { isMobile } from "react-device-detect"
import ImageGrid from "components/ImageGrid/index"
import CustomCommentCom from "./CustomCommentCom"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 5,
  },
  gridItem: {
    // padding: theme.spacing(2),
    paddingBottom: "0 !important",
  },
  desc: {
    marginBottom: 10,
    fontSize: "0.9rem",
  },
  name: {
    flex: 1,
    marginTop: 5,
    marginLeft: 5,
  },
  card: {
    width: isMobile ? "calc(100vw - 16px) !important" : 334,
  },
  listItem: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardAction: {
    paddingBottom: 6,
    paddingTop: 2,
  },
  comment: {
    paddingTop: 2,
    paddingBottom: 2,
  },
}))

const CirclePost = (props) => {
  const classes = useStyles()
  const openid = useSelector((state) => state.auth.user.openid)
  const shareItem = props?.shareItem
  const item =
    props.item?.properties?.context &&
    JSON.parse(props.item?.properties?.context)
  const listItem = props?.listItem
  const context = shareItem || item || listItem
  const id = context?.id
  const [zan, setZan] = useState(false)

  const [comments, setComments] = useState(context?.comments)
  const [comment, setComment] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [replyid, setReplyid] = useState(null)
  const [replyto, setReplyto] = useState(null)
  const [initQuery, setInitQuery] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [placeholder, setPlaceholder] = useState(
    `Reply to: ${context?.creator?.profile?.nickname}`
  )
  const [showEditor, setShowEditor] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [deleteContent, setDeleteContent] = useState(null)
  const [deleted, setDeleted] = useState(false)

  const changeComment = (e) => {
    setComment(e.target.value)
  }

  const submitComment = async () => {
    if (!submitting && comment) {
      setSubmitting(true)
      const data = {
        comment: comment,
        cate: "circle",
        id: context?.id,
        reply_id: replyid,
        reply_to: replyto,
      }
      const res = await apiPost(endpoints.comment, data)
      if (res?.id) {
        setComments([...comments, res])
      }
      setSubmitting(false)
    }
  }

  const replyClick = (id, commentter) => () => {
    setReplyid(id)
    setReplyto(commentter?.openid)
    setPlaceholder(`Reply to: ${commentter?.profile.nickname}`)
    setShowEditor(true)
  }

  useEffect(() => {
    const timer = () => {
      setTimeout(
        () =>
          apiGet(
            `${endpoints.zan_check}?cate=circle&action=check&id=${context?.id}`
          ).then((res) => setZan(res.zan)),
        Math.floor(Math.random() * Math.floor(5000))
      )
      setTimeout(
        () =>
          apiGet(`${endpoints.comment}?cate=circle&id=${context?.id}`).then(
            (res) => {
              const comments = res?.comments
              if (comments) {
                setComments(res.comments)
              }
            }
          ),
        Math.floor(Math.random() * Math.floor(50000))
      )
    }
    if (!initQuery) {
      timer()
      setInitQuery(true)
    }
  }, [context, initQuery, setInitQuery])

  const zanClick = () => {
    apiGet(
      `${endpoints.zan_check}?cate=circle&action=add&id=${context?.id}`
    ).then((res) => setZan(res.zan))
  }

  const deleteClick = (id) => () => {
    setShowDelete(true)
    setDeleteContent(id)
  }

  const handleCloseDelete = (deleted = false) => () => {
    setShowDelete(false)
    if (deleted) {
      setDeleted(true)
    }
  }

  return (
    <>
      {deleted ? null : (
        <>
          <ShareDialog
            cate="circle"
            itemId={id}
            open={shareOpen}
            handleClose={() => setShareOpen(false)}
          />
          <DeleteDialog
            cate="circle"
            itemId={deleteContent}
            handleClose={handleCloseDelete}
            open={showDelete}
          />
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              <Card className={listItem ? classes.listItem : classes.card}>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="iwhver"
                      className={classes.avatar}
                      src={context.creator?.profile?.avatarUrl}
                    />
                  }
                  title={context.creator?.profile?.nickname}
                  subheader={moment(context.created_on).format(
                    "YYYY MMM Do h:mm a"
                  )}
                />
                {context.images.length > 0 && (
                  <ImageGrid images={context.images} />
                )}
                <CardContent className={classes.cardContent}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    paragraph
                  >
                    {context.content}
                  </Typography>
                  <Divider className={classes.divider} />
                  {context.zan && (
                    <Typography variant="caption" component="p">
                      <Favorite fontSize="small" color="primary" />
                      {context.zan}
                    </Typography>
                  )}
                  {comments.length
                    ? comments.map((comment) => (
                        <CustomCommentCom
                          key={comment.id}
                          comment={comment}
                          replyClick={replyClick}
                          cate="circle"
                          showDeleteButton={openid === comment?.user?.openid}
                        />
                      ))
                    : null}
                  {showEditor && (
                    <Editor
                      value={comment}
                      onChange={changeComment}
                      onSubmit={submitComment}
                      submitting={submitting}
                      placeholder={placeholder}
                    />
                  )}
                </CardContent>
                <CardActions disableSpacing className={classes.cardAction}>
                  <IconButton
                    aria-label="add to favorites"
                    onClick={zanClick}
                    size="small"
                  >
                    <Favorite color={zan ? "primary" : "action"} />
                  </IconButton>
                  <IconButton
                    aria-label="share"
                    onClick={() => setShareOpen(true)}
                    size="small"
                  >
                    <Share />
                  </IconButton>
                  {context?.creator?.openid === openid && (
                    <IconButton size="small" onClick={deleteClick(context?.id)}>
                      <Delete />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="comment"
                    className={classes.expand}
                    size="small"
                    onClick={() => {
                      setReplyid(null)
                      setReplyto(null)
                      setPlaceholder(
                        `Reply to: ${context?.creator?.profile?.nickname}`
                      )
                      setShowEditor(!showEditor)
                    }}
                  >
                    <Badge
                      count={context.comments.length}
                      style={{ backgroundColor: "#52c41a" }}
                    >
                      <Comment />
                    </Badge>
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default CirclePost
