import React, {useState} from "react"
import { makeStyles } from "@material-ui/core/styles"
import {TextField} from "@material-ui/core"
import ImageUpload from "./ImageUpload"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    width: "100%"
  },
  listContainer: {
    width: "100%"
  }
})
)

const CircleCreate = (props) => {
  const { formatMessage: f } = useIntl()
  const selectedPoint = props?.selectedPoint
  const setRefreshLatest = props?.setRefreshLatest
  const setSelectedPoint = props?.setSelectedPoint
  const isList = props?.isList
  const [post, setPost] = useState("")
  const classes = useStyles()
  const [posted, setPosted] = useState(false)
  const handleChangeInput = (e) => {
    setPost(e.target.value)
    setPosted(false)
  }
  return (
    <div className={isList ? classes.listContainer: classes.container}>
       <TextField
          fullWidth
          label={f({id: "yourthought"})}
          multiline
          rows={4}
          value={post}
          placeholder={f({id: "yourthought"})}
          variant="outlined"
          onChange={handleChangeInput}
        />
        <ImageUpload post={post} posted={posted} setPosted={setPosted} selectedPoint={selectedPoint} setSelectedPoint={setSelectedPoint} setRefreshLatest={setRefreshLatest}/>
    </div>
  )
}

export default CircleCreate
