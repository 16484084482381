import React, { Component } from "react"

import { Link } from "react-router-dom"

import { withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import { AppBar, Toolbar } from "@material-ui/core"

import iwhverLogo from "assets/images/iwhverIcon.png"
import iwhverLogoW from "assets/images/iwhverw.png"
import RightIcons from "./RightIcons/RightIcons.js"
import LeftMenu from "./LeftIcons/LeftMenu"

const drawerWidth = 240

const styles = (theme) => ({
  root: {
    width: "100%",
    height: 430,
    marginTop: theme.spacing(3),
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  appBar: {
    position: "absolute",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 940,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  "appBarShift-left": {
    marginLeft: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  flex: {
    padding: 2,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    height: 45,
  },
  flexcenter: {
    flex: 1,
    padding: 2,
    height: 45,
  },
  icon: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    borderRadius: 20,
  },
  iconcenter: {
    height: "100%",
    borderRadius: 20,
    display: "flex",
    margin: "auto"
  }
})

class HeaderBar extends Component {
  constructor() {
    super()
    this.state = {
      slow: false,
    }
  }

  UNSAFE_componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleDrawerOpen = () => {
    this.props.leftMenuToggle()
  }

  render() {
    const { classes } = this.props
    const open = this.props.leftMenuState
    return (
      <AppBar
        color={this.state.slow ? "secondary" : "primary"}
        className={classNames(classes.appBar, {
          [classes.appBarShift]: open,
          [classes[`appBarShift-left`]]: open,
        })}
      >
        <Toolbar disableGutters={!open} style={{ minHeight: 64 }}>
          <LeftMenu
            rightMenuToggle={this.props.rightMenuToggle}
            rightMenuState={this.props.rightMenuState}
            menuContext={this.props.menuContext}
            auth={this.props.auth}
          />
          <div className={classes.flex}>
            <Link to="/dashboard/map/">
              <img
                src={iwhverLogo}
                className={classes.icon}
                alt="iwhver icon"
              />
            </Link>
          </div>
          <div className={classes.flexcenter}>
            <a href="https://iwhver.com" target="_blank" rel="noopener noreferrer">
              <img
                src={iwhverLogoW}
                className={classes.iconcenter}
                alt="iwhver icon w"
              />
            </a>
          </div>
          <RightIcons
            rightMenuToggle={this.props.rightMenuToggle}
            rightMenuState={this.props.rightMenuState}
            menuContext={this.props.menuContext}
            auth={this.props.auth}
          />
        </Toolbar>
      </AppBar>
    )
  }
}

export default withStyles(styles, { withTheme: true })(HeaderBar)
