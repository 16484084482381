import React, { useState, useEffect } from "react"
import { Grid, Tabs, Tab } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import circleLogo from "assets/images/circle.png"
import eventLogo from "assets/images/event.png"
import workLogo from "assets/images/work.png"
import marketLogo from "assets/images/market.png"
import { endpoints } from "lib/end-points"
import { apiGet } from "lib/api-call"
import ItemList from "./ItemList"
import { isMobile } from "react-device-detect"
import { useIntl } from "react-intl"


const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc(100vh - 65px)",
    backgroundColor: "#b4daf0",
  },
  centerRoot: {
    margin: "auto",
  },
  rootMobile: {
    position: "fixed",
    zIndex: 10,
    bottom: 0,
    height: "calc(50vh - 64px)",
    width: "100%",
    backgroundColor: "white",
    overflow: "auto",
  },
  gridContainer: {
    background: "#b4daf0",
    minHeight: "calc(100vh - 65px)",
    flexDirection: "row",
  },
  gridList: {
    background: "#b4daf0",
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto"
  },
  gridTab: {
    backgroundColor: "#6db4ea",
    display: "flex",
    justifyContent: "flex-end",
  },
  gridTabMobile: {
    backgroundColor: "#6db4ea",
  },
  tabs: {
    backgroundColor: "white",
    position: "fixed",
    zIndex: 10,
  },
  tabsMobile: {
    backgroundColor: "white",
    position: "fixed",
    zIndex: 10,
    bottom: 0,
  },
  wrapper: {
    flexDirection: "row",
  },
  selected: {
    backgroundColor: "#b4daf0",
  },
  tab: {
    backgroundColor: "#6db4ea",
    minWidth: 60,
  },
  textColorPrimary: {
    backgroundColor: "#6db4ea",
  },
}))

const TabsCom = (props) => {
  const classes = useStyles()
  const { formatMessage: f } = useIntl()
  const cate = props?.cate
  const changeTab = props?.changeTab
  const tabWidth = props?.tabWidth
  return (
    <Tabs
      value={cate}
      orientation={isMobile ? "horizontal" : "vertical"}
      onChange={changeTab}
      variant='fullWidth'
      textColor="secondary"
      className={isMobile ? classes.tabsMobile : classes.tabs}
      style={{ width: tabWidth }}
    >
      <Tab
        label={f({id: "circle"})}
        value="circle"
        classes={{
          root: classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<img src={circleLogo} width="36" alt="circle" />}
      />
      <Tab
        label={f({id: "work"})}
        value="work"
        classes={{
          root: classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<img src={workLogo} width="36" alt="work" />}
      />
      <Tab
        label={f({id: "event"})}
        value="event"
        classes={{
          root: classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<img src={eventLogo} width="36" alt="event" />}
      />
      <Tab
        label={f({id: "market"})}
        value="market"
        classes={{
          root: classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<img src={marketLogo} width="36" alt="market" />}
      />
    </Tabs>
  )
}

const FeedsList = (props) => {
  const classes = useStyles()
  const [cate, setCate] = useState("circle")
  const [loading, setLoading] = useState(false)
  const [tabWidth, setTabWidth] = useState("100%")
  const [cateState, setCateState] = useState({
    circle: {
      page: 1,
      nextPage: true,
      posts: [],
    },
    work: {
      page: 1,
      nextPage: true,
      posts: [],
    },
    market: {
      page: 1,
      nextPage: true,
      posts: [],
    },
    event: {
      page: 1,
      nextPage: true,
      posts: [],
    },
  })

  const [load, setLoad] = useState(true)

  const changeTab = (e, tab) => {
    setCate(tab)
  }

  const loadMorePost = (cate, refresh = false) => () => {
    if (refresh) {
      setCateState((stateNow) => {
        return {
          ...stateNow,
          [cate]: { page: 1, nextPage: true, posts: [] },
        }
      })
    } else {
      setCateState((stateNow) => {
        return {
          ...stateNow,
          [cate]: { ...stateNow[cate], page: stateNow[cate].page + 1 },
        }
      })
    }
    setLoad(true)
  }

  // pull data from cloud
  useEffect(() => {
    const cateDataNow = cateState[cate]
    if (cateDataNow.nextPage && (load || !cateDataNow.posts.length)) {
      setLoad(false)
      setLoading(true)
      apiGet(
        `${endpoints.listitem}?cate=${cate}&page=${cateDataNow.page}`
      ).then((res) => {
        setLoading(false)
        const code = res?.code
        if (code === 0) {
          setCateState((stateNow) => {
            const result = {
              nextPage: res.next_page,
              page: cateDataNow.page,
              posts: cateDataNow.posts.concat(res.result),
            }
            return {
              ...stateNow,
              [cate]: result,
            }
          })
        }
      })
    }
  }, [cate, cateState, load])

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12} sm={12} lg={6} className={classes.centerRoot}>
        <Grid container className={classes.gridContainer} spacing={0}>
          <Grid
            item
            xs={isMobile ? false : 2}
            sm={isMobile ? false : 2}
            className={isMobile ? classes.gridTabMobile : classes.gridTab}
          >
            {!isMobile && <TabsCom cate={cate} changeTab={changeTab} />}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={10}
            className={classes.gridList}
            ref={(refElem) => {
              if (refElem) {
                setTabWidth(refElem.clientWidth - 10)
              }
            }}
          >
            {isMobile && (
              <TabsCom cate={cate} changeTab={changeTab} tabWidth={tabWidth} />
            )}
            <ItemList
              posts={cateState.circle.posts}
              hasMore={cateState.circle.nextPage}
              loadMorePost={loadMorePost}
              show={cate === "circle"}
              cate={"circle"}
              loading={loading}
              key="circle"
            />
            <ItemList
              posts={cateState.work.posts}
              loadMorePost={loadMorePost}
              hasMore={cateState.work.nextPage}
              show={cate === "work"}
              cate={"work"}
              loading={loading}
              key="work"
            />
            <ItemList
              posts={cateState.event.posts}
              loadMorePost={loadMorePost}
              hasMore={cateState.event.nextPage}
              show={cate === "event"}
              cate={"event"}
              key="event"
            />
            <ItemList
              posts={cateState.market.posts}
              hasMore={cateState.market.nextPage}
              loadMorePost={loadMorePost}
              show={cate === "market"}
              cate={"market"}
              key="market"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FeedsList
