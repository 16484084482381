import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  GridListTile,
  GridList,
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { useIntl } from "react-intl"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    paddingLeft: 16,
    paddingRight: 16,
  },
  gridList: {
    width: "100%",
    maxHeight: 450,
  },
  img: {
    height: "100%",
    width: "auto"
  },
  tilelist: {
    display: "flex",
    position: "center"
  },
  tile: {
    margin: "auto"
  }

}))

const ImageGrid = (props) => {
  const images = props?.images
  const { formatMessage: f } = useIntl()
  const [showImage, setShowImage] = useState("")
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const handleClose = () => {
    setShowImage("")
  }
  return (
    <>
      <Dialog
        open={showImage !== ""}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <img src={showImage} alt="img" style={{ width: "100%" }} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {f({id: "close"})}
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.root}>
        <GridList cellHeight={200} className={classes.gridList} cols={images.length > 2 ? 3 : images.length}>
          {images.map((image, index) => (
            <GridListTile key={index} cols={image.cols || 1} classes={{root: classes.tilelist, tile: classes.tile}}>
              <img
                src={image.url}
                alt="img"
                className={classes.img}
                onClick={() => setShowImage(image.url)}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    </>
  )
}

export default ImageGrid
