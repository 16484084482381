import React from "react"
import { Form, Button as AntButton, Input } from "antd"

const { TextArea } = Input

const Editor = ({ onChange, onSubmit, submitting, value, placeholder }) => (
  <div>
    <Form.Item>
      <TextArea
        rows={2}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </Form.Item>
    <Form.Item>
      <AntButton
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Comment
      </AntButton>
    </Form.Item>
  </div>
)

export default Editor
