import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import Template from "./Template"
import ClientList from "./ClientList"
import { makeStyles } from "@material-ui/core/styles"
import Header from "components/Header/"
import { Tabs, Tab } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    marginTop: 0,
    zIndex: 1,
    overflow: "hidden",
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  content: {
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "calc(100% - 64px)",
    overflow: "auto",
    marginTop: 64,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 64px)",
      marginTop: 64,
    },
  },
  container: {
    padding: 10,
  },
}))

const CRM = () => {
  const classes = useStyles()

  const [usedTemplate, setUsedTemplate] = useState({
    name: "",
    email: "",
    sms: "",
    subject: "",
    id: null,
    send_email: true,
    send_sms: true,
    use_n_contact: true
  })

  const [country, setCountry] = useState("au")

  return (
    <div className={classes.root}>
      <div className={classes.appFrame}>
        <Header />
        <main className={classes.content}>
          <Grid
            xs={12}
            item
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Tabs
              value={country}
              onChange={(event, value) => setCountry(value)}
            >
              {["au", "nz"].map((dir) => (
                <Tab
                  value={dir}
                  label={dir === "au" ? "澳大利亚" : "新西兰"}
                  key={dir}
                  style={{
                    backgroundColor: country === dir ? "#9170b4" : "white",
                  }}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid container spacing={2} className={classes.container}>
            <Template setUsedTemplate={setUsedTemplate} />
            <Grid xs={12} item>
              <ClientList template={usedTemplate} country={country} />
            </Grid>
          </Grid>
        </main>
      </div>
    </div>
  )
}

export default CRM
