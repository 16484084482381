import React, { useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { endpoints } from "lib/end-points"
import { apiGet } from "lib/api-call"

const DeleteDialog = (props) => {
  const cate = props?.cate
  const itemId = props?.itemId
  const isComment = props?.isComment || false
  const open = props?.open
  const handleClose = props?.handleClose
  const [result, setResult] = useState("")

  const handleDelete = () => {
    const deleteUrl = `${
      endpoints.deletecontent
    }?cate=${cate}&id=${itemId}&comment=${isComment ? "true" : "false"}`
    apiGet(deleteUrl).then((res) => {
      const code = res?.code
      if (code === 0) {
        setResult("s")
        handleClose(true)()
      } else {
        setResult("e")
        handleClose()()
      }
    })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose()}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogContent>
          <Typography>是否确认删除该内容？</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDelete} color="primary">
            Confirm Delete
          </Button>
          <Button autoFocus onClick={handleClose()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={result !== ""}
        autoHideDuration={6000}
        onClose={() => setResult("")}
      >
        <Alert
          onClose={handleClose}
          severity={result === "s" ? "success" : "warning"}
        >
          {result === "s" ? "Delete success" : "Error, try it again"}
        </Alert>
      </Snackbar>
    </>
  )
}

export default DeleteDialog
