import React, { useState, useEffect, useRef } from "react"
import ReactTable from "react-table-v6"
import "react-table-v6/react-table.css"
import { apiGet, apiPost } from "lib/api-call"

import {
  Paper,

  Grid,
  Typography,
  Checkbox,
  IconButton,
  Toolbar,
  Fab,
  Tooltip,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogActions
} from "@material-ui/core"
import { endpoints } from "lib/end-points"
import {
  Refresh,
  Link,
  Visibility,
  AddToHomeScreen,
  Send,
  Gavel,
  Attachment,
  Delete,
  Add,
} from "@material-ui/icons"
import ClientPreview from "./ClientPreview"
import ClientStatus from "./ClientStatus"
import ClientExtra from "./ClientExtra"
import moment from "moment"

const ClientList = ({ country, template }) => {
  const [clients, setClients] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedClients, setSelectedClients] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectThisPage, setSelectThisPage] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState("")
  const [confirmAction, setConfirmAction] = useState(null)
  const [focusCustomer, setFocusCustomer] = useState(null)
  const [previewContent, setPreviewContent] = useState({
    sms: "",
    subject: "",
    email: "",
    hint: "",
    send_email: false,
    send_sms: "",
    notice_email: "",
    notice_mobile: "",
    use_n_contact: true
  })
  const [sendingResult, setSendingResult] = useState({
    status: "",
    results: [],
  })
  const testContact = {
    test_email: "446948216@qq.com",
    test_mobile: "18626216693",
    useContact: false,
  }
  const [showDialog, setShowDialog] = useState(false)
  const [extraContacts, setExtraContacts] = useState([])

  let reactTable = useRef(null)

  const getClient = () => {
    setLoading(true)
    apiGet(`${endpoints.crm_clients}?country=${country}`).then((res) => {
      setClients(res)
      setLoading(false)
    })
  }

  const refreshClients = () => {
    setLoading(true)
    apiGet(`${endpoints.crm}refresh/?country=${country}`).then((res) => {
      setLoading(false)
      getClient()
    })
  }

  useEffect(() => {
    const getClients = () => {
      setLoading(true)
      apiGet(`${endpoints.crm_clients}?country=${country}`).then((res) => {
        setClients(res)
        setLoading(false)
      })
    }
    getClients()
  }, [country])

  const fillColor = (field) => (state, rowInfo, column) => {
    return {
      style: {
        backgroundColor:
          rowInfo && rowInfo.original[field] === "Yes" ? "green" : null,
      },
    }
  }

  const handlePreview = (customer) => () => {
    console.log(template)
    setFocusCustomer(customer)
    setShowDialog(true)
    const data = {
      content: { ...template },
      receiver: {
        gogogo: "test",
        customer: customer.id,
      },
    }
    apiPost(endpoints.crm_preview, data).then((res) => {
      if (res?.code === 0) {
        setPreviewContent(res.results)
      } else {
        console.log("eee, in preview")
      }
    })
  }

  const handleTest = (testContact, customerId = null) => () => {
    customerId
      ? setSendingResult({
          ...sendingResult,
          status: "测试发送中，发到默认测试邮箱和手机",
        })
      : setPreviewContent({
          ...template,
          hint: "test send loading",
        })
    const data = {
      content: {
        ...template,
        notice_email: testContact?.test_email,
        notice_mobile: testContact?.test_mobile,
      },
      receiver: {
        gogogo: "test",
        customer: customerId || focusCustomer?.id,
      },
    }
    apiPost(endpoints.crm_message, data).then((res) => {
      if (res?.code === 0) {
        customerId
          ? setSendingResult({
              status: "测试发送成功",
              results: res.results,
            })
          : setPreviewContent({
              ...previewContent,
              hint: `Test Result: SMS is ${res.results[0]?.sms}, Email is ${res.results[0]?.email}`,
            })
      } else {
        customerId
          ? setSendingResult({
              ...sendingResult,
              status: "测试发送有问题",
            })
          : setPreviewContent({
              ...previewContent,
              hint: "Something error happend",
            })
      }
    })
  }

  const handleConfirmSend = (sendType) => () => {
    setConfirmDialog(sendType)
    if (sendType === 'choice1') {
      setConfirmAction(() => handleSend(testContact, country, true))
    } else if (sendType === 'choice2') {
      setConfirmAction(() => handleSend(testContact, country))
    } else {
      setConfirmAction(() => handleSend(testContact, selectedClients.join(",")))
    }
  }

  const handleSend = (testContact, customerId = null, extra = false) => () => {
    setConfirmDialog("")
    customerId
      ? setSendingResult({
          ...sendingResult,
          status: "！！！真实发送中！！！",
        })
      : setPreviewContent({
          ...template,
          hint: "!!!真实发送中!!!",
        })
    const data = {
      content: {
        ...template,
        notice_email: testContact.useContact ? testContact?.test_email : "",
        notice_mobile: testContact.useContact ? testContact?.test_mobile : "",
      },
      receiver: {
        gogogo: "go",
        customer: customerId || focusCustomer?.id,
        ...(extra && { extra_customers: extraContacts }),
      },
      ...(template?.sms_api_key_ad && {
        extra: {
          sms_api_key_ad: true,
        },
      }),
    }

    apiPost(endpoints.crm_message, data).then((res) => {
      if (res?.code === 0) {
        customerId
          ? setSendingResult({
              status: "发送结束",
              results: res.results,
            })
          : setPreviewContent({
              ...previewContent,
              hint: `真实发送结果: SMS is ${res.results[0]?.sms}, Email is ${res.results[0]?.email}`,
            })
      } else {
        customerId
          ? setSendingResult({
              ...sendingResult,
              status: "发送有问题",
            })
          : setPreviewContent({
              ...previewContent,
              hint: "Something error happend",
            })
      }
    })
  }

  const handleDialogClose = () => {
    setShowDialog(false)
    setFocusCustomer(null)
    setPreviewContent(null)
  }

  const handleDeleteAll = () => {
    apiGet(`${endpoints.crm_delete}?country=${country}`).then((res) => {
      setClients([])
    })
  }

  const handleAddTest = () => {
    apiGet(`${endpoints.crm_addtest}`).then((res) => {
      getClient()
    })
  }

  const handleClick = (customerId) => (e) => {
    const selectableCustomerList = reactTable.getResolvedState().sortedData
    let newSelected = []
    const checkAll = customerId === "all"
    const checkThisPage = customerId === "thispage"
    const allSelected = selectedClients.length === selectableCustomerList.length

    if (checkThisPage && selectThisPage) {
      setSelectThisPage(false)
      newSelected = []
    } else if (checkThisPage && !selectThisPage) {
      setSelectThisPage(true)
      const sortedData = reactTable.state.sortedData
      const page = reactTable.state.page
      const pageSize = reactTable.state.pageSize
      newSelected = [
        ...sortedData
          .slice(page * pageSize, (page + 1) * pageSize)
          .map((customer) => customer.id),
      ]
    } else if (checkAll && selectedAll) {
      setSelectedAll(false)
      newSelected = []
    } else if (checkAll && !allSelected) {
      newSelected = [...selectableCustomerList.map((file) => file.id)]
      setSelectedAll(true)
    } else if (checkAll && allSelected) {
      newSelected = []
      setSelectedAll(false)
    }

    const selectedIndex = selectedClients.findIndex(
      (theClientId) => theClientId === customerId
    )

    if (!checkAll && !checkThisPage) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedClients, customerId)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedClients.slice(1))
      } else if (selectedIndex === selectedClients.length - 1) {
        newSelected = newSelected.contact(selectedClients.slice(0, -1))
      } else if (selectedClients > 0) {
        newSelected = newSelected.concat(
          selectedClients.slice(0, selectedIndex),
          selectedClients.slice(selectedIndex + 1)
        )
      }
    }
    setSelectedClients(newSelected)
  }

  const columns = [
    {
      id: "id",
      Header: () => {
        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 0 }}
                  checked={selectedAll}
                  onChange={handleClick("all")}
                  value={"all"}
                  color="primary"
                />
              }
              label="All"
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ padding: 0 }}
                  checked={selectThisPage}
                  onChange={handleClick("thispage")}
                  value={"thispage"}
                  color="primary"
                />
              }
              label="This"
            />
          </>
        )
      },
      accessor: (d) => d.id,
      width: 140,
      filterable: false,
      sortable: false,
      Cell: ({ row }) => {
        const isSelected = selectedClients.findIndex((e) => e === row.id) !== -1
        return (
          <Checkbox
            style={{ padding: 0 }}
            checked={isSelected}
            onChange={handleClick(row.id)}
            value={toString(row.id)}
            color="primary"
          />
        )
      },
    },
    {
      Header: `URL`,
      accessor: "url",
      filterable: true,
      Cell: (props) => (
        <IconButton
          onClick={openUrl(props.original.url)}
          style={{ padding: 0 }}
        >
          <Link color="action" />
        </IconButton>
      ),
    },
    {
      Header: "国家",
      accessor: "country",
    },
    {
      Header: "Store",
      accessor: "store_id",
      filterable: true,
    },
    {
      Header: "taobaoid",
      accessor: "taobaoid",
      filterable: true,
    },
    {
      Header: "Passport",
      accessor: "passport",
      filterable: true,
    },
    {
      Header: "姓",
      accessor: "family_name",
      filterable: true,
    },
    {
      Header: "名",
      accessor: "given_name",
      filterable: true,
    },
    {
      Header: "性别",
      accessor: "gender",
      filterable: true,
    },
    {
      Header: "N mobile",
      accessor: "notice_mobile",
      filterable: true,
    },
    {
      Header: "N email",
      accessor: "notice_email",
      filterable: true,
    },
    {
      Header: "C mobile",
      accessor: "customer_mobile",
      filterable: true,
    },
    {
      Header: "C email",
      accessor: "customer_email",
      filterable: true,
    },
    ...(country === "au"
      ? [
          {
            Header: "期望日期",
            accessor: "wantday",
            filterable: true,
            multiSort: true,
          },
        ]
      : []),
    {
      Header: "抢这次",
      accessor: "is_this_time",
      filterable: true,
      getProps: fillColor("is_this_time"),
      multiSort: true,
    },
    {
      Header: "已付尾款",
      accessor: "is_credible",
      filterable: true,
      getProps: fillColor("is_credible"),
      multiSort: true,
    },
    {
      Header: "抢到了",
      accessor: "is_get",
      filterable: true,
      getProps: fillColor("is_get"),
      multiSort: true,
    },
    {
      Header: "我们抢到的",
      accessor: "is_weget",
      filterable: true,
      getProps: fillColor("is_weget"),
      multiSort: true,
    },

    {
      Header: "Preview",
      id: "preview",
      Cell: (props) => (
        <IconButton onClick={handlePreview(props.original)} size="small">
          <Visibility fontSize="small" />
        </IconButton>
      ),
      sortable: false,
    },
    {
      Header: "发给我",
      id: "test",
      Cell: (props) => (
        <IconButton
          onClick={handleTest(testContact, props.original.id)}
          size="small"
        >
          <AddToHomeScreen fontSize="small" />
        </IconButton>
      ),
      sortable: false,
    },
    {
      Header: "发给他",
      id: "send",
      Cell: (props) => (
        <IconButton
          onClick={handleSend(testContact, props.original.id)}
          size="small"
        >
          <Send fontSize="small" />
        </IconButton>
      ),
      sortable: false,
    },
  ]

  const openUrl = (url) => () => window.open(url)
  const checkTemplateExtra = () => {
    if (
      !template?.email.includes("<<<") &&
      !template?.sms.includes("<<<") &&
      !template?.subject.includes("<<<")
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Dialog
        onClose={() => setConfirmDialog(false)}
        open={confirmDialog ? true : false}
        fullWidth
      >
        <DialogTitle>真的要发吗？？真的要发吗？？？不再考虑一下吗？？？</DialogTitle>
        <DialogActions>
          <Tooltip title="添加">
            <IconButton onClick={confirmAction} size="medium"  color="primary">
              Confirm
            </IconButton>
          </Tooltip>
          <IconButton onClick={() => setConfirmDialog(false)} size="small">
            Close
          </IconButton>
        </DialogActions>
      </Dialog>
      <ClientPreview
        previewContent={previewContent}
        handleDialogClose={handleDialogClose}
        showDialog={showDialog}
        handleTest={handleTest}
        handleSend={handleSend}
      />
      {/* <Paper>
        <FormControl fullWidth style={{ height: 100 }}>
          <Button
            startIcon={<Refresh />}
            variant="contained"
            onClick={refreshClients}
            color="primary"
            style={{ height: 100, fontSize: 30 }}
          >
            刷新信息
          </Button>
        </FormControl>
      </Paper> */}
      <Grid container style={{ marginTop: 10 }}>
        <Grid xs={6} item>
          <ClientStatus sendingResult={sendingResult} />
        </Grid>
        <Grid xs={6} item>
          <ClientExtra
            showAdd={checkTemplateExtra()}
            extraContacts={extraContacts}
            setExtraContacts={setExtraContacts}
          />
        </Grid>
      </Grid>
      <Paper style={{ marginTop: 10 }}>
        <Toolbar style={{ width: "100%" }}>
          <Typography variant="h4" style={{ flex: 1 }}>{`客户信息(选中${
            selectedClients.length
          }), 更新时间${
            clients?.length
              ? moment(clients[0].updated_at).format("YYYY-MM-DD hh:mm A")
              : "Unknown"
          }`}</Typography>
          <Tooltip
            title={`刷新信息`}
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto"}}
          >
            <Fab
              size="large"
              color="primary"
              onClick={refreshClients}
              component="div"
              style={{ marginRight: 80 }}
            >
             <Refresh />
            </Fab>
          </Tooltip>
          <Tooltip
            title={`发送全部${country}客户，包括附加收件人`}
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto" }}
          >
            <Fab
              size="large"
              color="primary"
              disabled={!checkTemplateExtra()}
              // onClick={handleSend(testContact, country, true)}
              onClick={handleConfirmSend('choice1')}
              component="div"
            >
              <Attachment />
            </Fab>
          </Tooltip>
          <Tooltip
            title={`发送全部${country}客户`}
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto" }}
          >
            <Fab
              size="large"
              color="primary"
              // onClick={handleSend(testContact, country)}
              onClick={handleConfirmSend('choice2')}
              component="div"
            >
              <Gavel />
            </Fab>
          </Tooltip>
          <Tooltip
            title="正式发送全部选中客户！！！"
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto" }}
          >
            <Fab
              size="large"
              color="primary"
              style={{ marginLeft: 20 }}
              disabled={selectedClients.length < 1}
              onClick={handleConfirmSend('choice3')}
              // onClick={handleSend(testContact, selectedClients.join(","))}
              component="div"
            >
              <Send size="large" />
            </Fab>
          </Tooltip>
          <Tooltip
            title="删除所有客户"
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto" }}
          >
            <Fab
              size="large"
              color="primary"
              style={{ marginLeft: 20 }}
              onClick={handleDeleteAll}
              component="div"
            >
              <Delete size="large" />
            </Fab>
          </Tooltip>
          <Tooltip
            title="添加测试客户"
            placement={"top-start"}
            enterDelay={200}
            style={{ margin: "auto" }}
          >
            <Fab
              size="large"
              color="primary"
              style={{ marginLeft: 20 }}
              onClick={handleAddTest}
              component="div"
            >
              <Add size="large" />
            </Fab>
          </Tooltip>
        </Toolbar>
        <ReactTable
          ref={(r) => (reactTable = r)}
          data={clients}
          columns={columns}
          style={{ textAlign: "center" }}
          defaultPageSize={30}
          loading={loading}
          SubComponent={(row) => {
            const { original } = row
            return (
              <Grid container style={{ padding: 10 }}>
                <Grid item>
                  <Typography variant="body2">
                    <b>C mobile: {original?.customer_mobile}</b>,
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <b>C email: {original?.customer_email}</b>,
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <b>birthday: {original?.birthday}</b>,
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <b>passport_expire: {original?.passport_expire}</b>,
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    <b>wantday: {original?.wantday}</b>,
                  </Typography>
                </Grid>
              </Grid>
            )
          }}
        />
      </Paper>
    </>
  )
}

export default ClientList
