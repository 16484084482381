import React, { useState } from "react"
import { IconButton, MenuItem, Menu } from "@material-ui/core"
import Person from "@material-ui/icons/Person"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useIntl } from "react-intl"

export default () => {
  const { formatMessage: f } = useIntl()
  const [anchorEl, setAnchorEl] = useState(null)
  const userId = useSelector((state) => state.auth.user?.userId)

  const dispatch = useDispatch()

  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        className="step-account-menu"
        onClick={handleMenu}
      >
        <Person />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {userId < 3 && (
          <MenuItem onClick={handleClose} to="/crm" component={Link}>
            CRM
          </MenuItem>
        )}
        <MenuItem onClick={() => dispatch({ type: "USER_LOGOUT" })}>
          {f({ id: "signOut" })}
        </MenuItem>
      </Menu>
    </>
  )
}
