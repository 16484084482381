import React, {Suspense} from "react"
import "./App.css"
import { Route, HashRouter, Switch, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import AuthRoute from "components/auth/AuthRoute"
import Auth from "views/Auth/"
import CRM from "views/CRM/"
import UI from "views/index"
import { IntlProvider } from "react-intl"
import { translationMessages } from "./i18n.js"
import { CircularProgress } from "@material-ui/core"
import Feedback from "components/Feedback"

function App() {
  const en = useSelector(state => state.auth.user.initEn)

  return (
    <HashRouter>
      <IntlProvider locale={en ? "en": 'zh'} messages={translationMessages[en ? "en": 'zh']}>
      <Suspense fallback={<CircularProgress />}>
        <>
          <Feedback />
          <Switch>
            <AuthRoute path="/auth" component={Auth} />
            <AuthRoute path="/dashboard/:mode" component={UI} />
            <AuthRoute path="/crm" component={CRM} />
            <Route render={() => <Redirect to="/dashboard/map" />} />
          </Switch>
        </>
        </Suspense>
      </IntlProvider>
    </HashRouter>
  )
}

export default App
