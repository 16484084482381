import React from "react"

import { Route, Switch, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import FeedsList from "./Feeds/List"
import MapList from "./Feeds/Map"
import MineList from "./Feeds/Mine"

export default (props) => {
  const mode = props?.match?.params?.mode
  const loggedIn = useSelector((state) => state.auth.user.loggedIn)
  return (
    <Switch>
      {loggedIn && mode === "list" && (
        <Route
          path={`${props.match.url}`}
          render={(props) => <FeedsList {...props} />}
        />
      )}
      {loggedIn && mode === "mine" && (
        <Route
          path={`${props.match.url}`}
          render={(props) => <MineList {...props} />}
        />
      )}
      <Route
        path={`${props.match.url}`}
        render={(props) => <MapList {...props} />}
      />
      <Redirect
        to={{
          pathname: "/dashboard/map/",
          state: { referrer: props.location },
          search: props.location.search,
        }}
      />
    </Switch>
  )
}
