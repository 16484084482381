export const USER_SET = "USER_SET"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_SET_PROFILE = "USER_SET_PROFILE"
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST"
export const USER_PROFILE_REQUEST_ERROR = "USER_PROFILE_REQUEST_ERROR"
export const USER_PROFILE_REQUEST_SUCCESS = "USER_PROFILE_REQUEST_SUCCESS"
export const USER_PROFILE_SHOW_TOUR = "USER_PROFILE_SHOW_TOUR"
export const USER_PROFILE_SET_SHOW_TOUR = "USER_PROFILE_SET_SHOW_TOUR"
export const USER_SHOW_FEEDBACK_FORM = "USER_SHOW_FEEDBACK_FORM"
export const USER_CHANGE_EN = "USER_CHANGE_EN"
