import React from "react"

const CommentContent = (props) => {
  const comment = props.comment
  const replyto = comment?.replyto
  return (
    <>
      {replyto ? (
        <p>
          <span style={{ color: "green" }}>
            Reply to: {replyto?.profile?.nickname}:{" "}
          </span>
          {comment.comment}
        </p>
      ) : (
        <p>{comment.comment}</p>
      )}
    </>
  )
}

export default CommentContent
