import React, { useState } from "react"
import "antd/dist/antd.css"
import { makeStyles } from "@material-ui/core/styles"
import { Upload, Modal } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { Button, CircularProgress } from "@material-ui/core"
import HmacSHA1 from "crypto-js/hmac-sha1"
import Base64 from "crypto-js/enc-base64"
import moment from "moment"
import { apiPost, apiGet } from "lib/api-call"
import { useIntl } from "react-intl"

const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const useStyles = makeStyles((theme) => ({
  button: {
    "&:focus, &:hover, &$active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))

const PicturesWall = (props) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  const desc = props?.desc
  const qiuChu = props?.qiuChu
  const country = props?.country
  const state = props?.state
  const category = props?.category
  const contact = props?.contact
  const location = props?.location

  const selectedPoint = props?.selectedPoint
  const setSelectedPoint = props?.setSelectedPoint
  const setRefreshLatest = props?.setRefreshLatest
  const posted = props?.posted
  const setPosted = props?.setPosted

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState("")
  const [previewTitle, setPreviewTitle] = useState("")
  const [fileList, setFileList] = useState([])

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    )
  }

  const handleChange = ({ fileList }) => {
    setFileList(fileList)
    setPosted(false)
  }

  const getEncodeFileName = (filename, key) => {
    const fileType = filename.split(".").pop(-1)
    const fileKey = Base64.stringify(HmacSHA1(filename, key.toString()))
      .toString()
      .replace(/[^a-z0-9]/gi, "")
      .toLowerCase()
    return `${fileKey}.${fileType}`
  }
  const handleUpload = async () => {
    if (loading || posted) return
    setLoading(true)
    const key = moment().unix()
    fileList.map(async (file) => {
      const fileKey = getEncodeFileName(file.name, key)
      const signUrl = `/v1/web/presigned_url/?key=${fileKey}&cate=market`
      const data = await apiGet(signUrl)
      const fields = data.s3.fields
      // upload to cloud
      const formData = new FormData()
      formData.append("key", fields.key)
      formData.append("AWSAccessKeyId", fields.AWSAccessKeyId)
      formData.append("policy", fields.policy)
      formData.append("acl", "public-read")
      formData.append("signature", fields.signature)
      formData.append("file", file.originFileObj)
      await fetch(data.s3.url, { method: "POST", body: formData })
      return file.name
    })
    const fileListName = fileList.map((file) =>
      getEncodeFileName(file.name, key)
    )

    const res = await apiPost("/v1/web/create_market/", {
      desc: desc,
      sob: qiuChu,
      country: country,
      state: state,
      category: category,
      location: location,
      contact: contact,
      imgs: fileListName,
      gps: `${selectedPoint.lat},${selectedPoint.lng}`,
    })
    setLoading(false)
    const { code } = res
    if (code !== 0) {
      setError(true)
    } else {
      setPosted(true)
      setTimeout(() => {
        setRefreshLatest(true)
        setSelectedPoint({ lat: 0, lng: 0 })
      }, 1000)
    }
    // create post
  }
  const beforeUpload = () => {
    return false
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  )
  return (
    <div className="clearfix">
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        multiple
        accept="image/*"
      >
        {fileList.length >= 9 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <Button
        variant="contained"
        color="default"
        className={classes.button}
        fullWidth
        onClick={handleUpload}
      >
        {loading ? (
          <CircularProgress />
        ) : error ? (
          f({ id: "error-hint" })
        ) : posted ? (
          f({ id: "thank-your-post" })
        ) : (
          f({ id: "post" })
        )}
      </Button>
    </div>
  )
}

export default PicturesWall
