export const endpoints = {
  users: "/core/api/config/users/",
  password: "/core/api/config/password/change/",
  resetPasswordEmail: "/core/api/config/password/reset/email/",
  confirmPassword: "/core/api/config/password/reset/confirm/",
  obtainTokens: "/api/token/",
  //
  latest_geojson: "/v1/web/geocode.json/",
  zan_check: "/v1/web/zan_check/",
  comment: "/v1/web/comment/",
  item: "/v1/web/item/",
  listitem: "/v1/web/list/",
  listmine: "/v1/web/listmine/",
  deletecontent: "/v1/web/deletecontent/",
  fblogin: "/v1/web/fblogin/",
  // crm
  notice_template: "/v1/crmtemplate/",
  crm: "/v1/crm/",
  crm_clients: "/v1/crm/country/",
  crm_message: "/v1/crm/message/",
  crm_preview: "/v1/crm/preview/",
  crm_delete: "/v1/crm/delete/",
  crm_addtest: "/v1/crm/addtest/"
}
