import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Link,
  Divider,
  Tooltip,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { Share, CheckCircle, Comment } from "@material-ui/icons"
import moment from "moment"
import Editor from "./Editor"
import { endpoints } from "lib/end-points"
import { apiGet, apiPost } from "lib/api-call"
import { Badge } from "antd"
import ShareDialog from "components/ShareDialog/index"
import { isMobile } from "react-device-detect"
import ImageGrid from "components/ImageGrid/index"
import CustomCommentCom from "./CustomCommentCom"

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: 5,
  },
  gridItem: {
    // padding: theme.spacing(2),
    paddingBottom: "0 !important",
  },
  desc: {
    marginBottom: 10,
    fontSize: "0.9rem",
  },
  name: {
    flex: 1,
    marginTop: 5,
    marginLeft: 5,
  },
  card: {
    width: isMobile ? "calc(100vw - 16px) !important" : 334,
  },
  listItem: {
    width: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  divider: {
    marginTop: 5,
    marginBottom: 5,
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardAction: {
    paddingBottom: 6,
    paddingTop: 2,
  },
}))

const Event = (props) => {
  const classes = useStyles()
  const openid = useSelector((state) => state.auth.user.openid)
  const shareItem = props?.shareItem
  const item =
    props.item?.properties?.context &&
    JSON.parse(props.item?.properties?.context)
  const listItem = props?.listItem
  const context = shareItem || item || listItem
  const id = context?.id
  const [go, setGo] = useState(false)
  const [goes, setGoes] = useState(context?.go)
  const [showComment, setShowComment] = useState(false)
  const [comments, setComments] = useState([])
  const [comment, setComment] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [replyid, setReplyid] = useState(null)
  const [replyto, setReplyto] = useState(null)
  const [initQuery, setInitQuery] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [placeholder, setPlaceholder] = useState(`Reply`)

  const changeComment = (e) => {
    setComment(e.target.value)
  }
  const submitComment = async () => {
    if (!submitting && comment) {
      setSubmitting(true)
      const data = {
        comment: comment,
        cate: "event",
        id: context?.id,
        reply_id: replyid,
        reply_to: replyto,
      }
      const res = await apiPost(endpoints.comment, data)
      if (res?.id) {
        setComments([...comments, res])
      }
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (showComment) {
      apiGet(`${endpoints.comment}?cate=event&id=${id}`).then((res) => {
        if (res?.code === 0) {
          setComments(res.comments)
        }
      })
    }
  }, [showComment, setComment, id])

  useEffect(() => {
    const timer = () => {
      setTimeout(
        () =>
          apiGet(
            `${endpoints.zan_check}?cate=event&action=check&id=${context?.id}`
          ).then((res) => {
            setGo(res.go)
            setGoes(res.goes)
          }),
        Math.floor(Math.random() * Math.floor(5000))
      )
    }
    if (!initQuery) {
      timer()
      setInitQuery(true)
    }
  }, [context, initQuery, setInitQuery])

  const goClick = () => {
    apiGet(
      `${endpoints.zan_check}?cate=event&action=add&id=${context?.id}`
    ).then((res) => setGo(res.go))
  }

  const replyClick = (id, commentter) => () => {
    setReplyid(id)
    setReplyto(commentter?.openid)
    setPlaceholder(`Reply to: ${commentter?.profile.nickname}`)
  }

  return (
    <>
      <ShareDialog
        cate="event"
        itemId={id}
        open={shareOpen}
        handleClose={() => setShareOpen(false)}
      />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} className={classes.gridItem}>
          <Card className={listItem ? classes.listItem : classes.card}>
            <CardHeader
              avatar={
                <Avatar
                  aria-label="iwhver"
                  className={classes.avatar}
                  src={context.thumb}
                />
              }
              title={context.title}
              subheader={moment(context.when).format("YYYY MMM Do h:mm a")}
            />
            {context.images?.length > 0 && (
              <ImageGrid images={context.images} />
            )}
            <CardContent className={classes.cardContent}>
              {context.url && (
                <Typography variant="body1">
                  Website:
                  <Link href={`${context.url}`} target="_blank">
                    {context.url}
                  </Link>
                </Typography>
              )}
              <Typography variant="body1">
                Location:
                <Link
                  href={`https://www.google.com/maps?q=${context.location}`}
                  target="_blank"
                >
                  {context.location}
                </Link>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {context.content}
              </Typography>
              <Divider className={classes.divider} />
              {showComment && (
                <>
                  {comments?.length
                    ? comments.map((comment) => (
                        <CustomCommentCom
                          comment={comment}
                          replyClick={replyClick}
                          cate="event"
                          showDeleteButton={openid === comment?.user?.openid}
                        />
                      ))
                    : null}
                  <Editor
                    value={comment}
                    onChange={changeComment}
                    onSubmit={submitComment}
                    submitting={submitting}
                    placeholder={placeholder}
                  />
                </>
              )}
            </CardContent>
            <CardActions disableSpacing className={classes.cardAction}>
              <Tooltip title="Attend">
                <IconButton
                  aria-label="add to favorites"
                  onClick={goClick}
                  size="small"
                >
                  <Badge
                    count={goes || 0}
                    style={{ backgroundColor: "#52c41a" }}
                  >
                    <CheckCircle color={go ? "primary" : "action"} />
                  </Badge>
                </IconButton>
              </Tooltip>
              <IconButton
                aria-label="share"
                onClick={() => setShareOpen(true)}
                size="small"
              >
                <Share />
              </IconButton>
              <IconButton
                aria-label="Go"
                size="small"
                className={classes.expand}
                onClick={() => {
                  setShowComment(!showComment)
                  setReplyid(null)
                  setReplyto(null)
                  setPlaceholder(`Reply`)
                }}
              >
                <Badge
                  count={comments.length || context.comment || 0}
                  style={{ backgroundColor: "#52c41a" }}
                >
                  <Comment />
                </Badge>
              </IconButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default Event
