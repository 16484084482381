import React, { useState, useEffect } from "react"
import { Grid, Tabs, Tab } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { endpoints } from "lib/end-points"
import { apiGet } from "lib/api-call"
import {
  Create as CreateIcon,
  Comment as CommentIcon,
  Favorite as FavoriteIcon,
} from "@material-ui/icons"
import ItemList from "./ItemList"
import { isMobile } from "react-device-detect"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc(100vh - 65px)",
    backgroundColor: "#b4daf0",
  },
  centerRoot: {
    margin: "auto",
  },
  rootMobile: {
    position: "fixed",
    zIndex: 10,
    bottom: 0,
    height: "calc(50vh - 64px)",
    width: "100%",
    backgroundColor: "white",
    overflow: "auto",
  },
  gridContainer: {
    background: "#b4daf0",
    minHeight: "calc(100vh - 65px)",
    flexDirection: "row",
  },
  gridList: {
    background: "#b4daf0",
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  gridTab: {
    backgroundColor: "#6db4ea",
    display: "flex",
    justifyContent: "flex-end",
  },
  gridTabMobile: {
    backgroundColor: "#6db4ea",
  },
  tabs: {
    backgroundColor: "white",
    position: "fixed",
    zIndex: 10,
  },
  tabsMobile: {
    backgroundColor: "white",
    position: "fixed",
    zIndex: 10,
    bottom: 0,
    width: "100%",
  },
  wrapper: {
    flexDirection: "row",
  },
  selected: {
    backgroundColor: "#b4daf0",
  },
  tab: {
    backgroundColor: "#6db4ea",
    minWidth: 60,
  },
  tabMobile: {
    backgroundColor: "#6db4ea",
  },
  textColorPrimary: {
    backgroundColor: "#6db4ea",
  },
  icon: {
    marginBottom: 0,
  },
}))

const TabsCom = (props) => {
  const { formatMessage: f } = useIntl()
  const classes = useStyles()
  const cate = props?.cate
  const changeTab = props?.changeTab
  const tabWidth = props?.tabWidth
  return (
    <Tabs
      width="100%"
      value={cate}
      orientation={isMobile ? "horizontal" : "vertical"}
      onChange={changeTab}
      variant="fullWidth"
      textColor="secondary"
      className={isMobile ? classes.tabsMobile : classes.tabs}
      style={{ width: tabWidth }}
    >
      <Tab
        label={f({id: "minepost"})}
        value="create"
        classes={{
          root: isMobile ? classes.tabMobile : classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<CreateIcon style={{ marginBottom: 0 }} fontSize="small" />}
      />
      <Tab
        label={f({id: "minecomment"})}
        value="comment"
        classes={{
          root: isMobile ? classes.tabMobile : classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<CommentIcon style={{ marginBottom: 0 }} fontSize="small" />}
      />
      <Tab
        label={f({id: "minesave"})}
        value="zan"
        classes={{
          root: isMobile ? classes.tabMobile : classes.tab,
          wrapper: classes.wrapper,
          selected: classes.selected,
          textColorPrimary: classes.textColorPrimary,
        }}
        icon={<FavoriteIcon fontSize="small" style={{ marginBottom: 0 }} />}
      />
    </Tabs>
  )
}

const FeedsList = (props) => {
  const classes = useStyles()
  const [cate, setCate] = useState("create")
  const [tabWidth, setTabWidth] = useState("100%")
  const [cateState, setCateState] = useState({
    create: {
      posts: [],
      load: true,
    },
    comment: {
      posts: [],
      load: true,
    },
    zan: {
      posts: [],
      load: true,
    },
  })

  const changeTab = (e, tab) => {
    setCate(tab)
  }

  // pull data from cloud
  useEffect(() => {
    if (cateState[cate].load) {
      apiGet(`${endpoints.listmine}?cate=${cate}`).then((res) => {
        const code = res?.code
        if (code === 0) {
          setCateState((stateNow) => {
            return {
              ...stateNow,
              [cate]: { posts: res.result, load: false },
            }
          })
        }
      })
    }
  }, [cate, cateState])

  return (
    <Grid container spacing={0} className={classes.root}>
      <Grid item xs={12} sm={12} lg={6} className={classes.centerRoot}>
        <Grid container className={classes.gridContainer} spacing={0}>
          <Grid
            item
            xs={isMobile ? false : 2}
            sm={isMobile ? false : 2}
            className={isMobile ? classes.gridTabMobile : classes.gridTab}
          >
            {!isMobile && <TabsCom cate={cate} changeTab={changeTab} />}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={10}
            className={classes.gridList}
            ref={(refElem) => {
              if (refElem) {
                setTabWidth(refElem.clientWidth - 10)
              }
            }}
          >
            {isMobile && (
              <TabsCom cate={cate} changeTab={changeTab} tabWidth={tabWidth} />
            )}
            <ItemList
              posts={cateState.create.posts}
              load={cateState.create.load}
              show={cate === "create"}
              key="create"
            />
            <ItemList
              posts={cateState.comment.posts}
              load={cateState.comment.load}
              show={cate === "comment"}
              key="comment"
            />
            <ItemList
              posts={cateState.zan.posts}
              load={cateState.zan.load}
              show={cate === "zan"}
              key="zan"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FeedsList
