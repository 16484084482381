import React from "react"
import ErrorBoundaryDialog from "./ErrorBoundaryDialog"
// import * as Sentry from "@sentry/browser"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: null,
      info: null
    }
  }
  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info
    })
    // Sentry.captureException(error)
  }
  render() {
    const { hasError, error, info } = this.state
    if (hasError) {
      return <ErrorBoundaryDialog error={error} info={info} />
    }
    return this.props.children
  }
}
