import React from "react"
import { Button } from "@material-ui/core"

const ReplyTo = ({ id, replyClick, commentter }) => {
  return (
    <Button key="comment-list-reply-to-0" variant="contained" onClick={replyClick(id, commentter)}>
      Reply to
    </Button>
  )
}

export default ReplyTo
