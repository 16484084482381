import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Tour from "reactour"
import { Typography } from "@material-ui/core"

const steps = [
  {
    selector: '[data-tour="toolbar"]',
    content: "功能条区域，可以选择在地图上是否显示该图层信息",
  },
  {
    selector: '[data-tour="satellite"]',
    content: "是否展示卫星地图页面，可以点击看一看效果",
  },
  {
    selector: '[data-tour="specialnorth"]',
    content: () => (
      <div>
        <p>南回归线以北，可以从事以下类别工作集二签: </p>
        <ul>
          <li>农牧业</li>
          <li>渔业</li>
          <li>旅游业</li>
          <li>伐木业</li>
          <li>建筑业</li>
        </ul>
        <Typography>
          关于二签信息，可以点击
          <a
            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/work-holiday-462/second-work-holiday-462"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            官方链接
          </a>
          查看，也可以前往我们的
          <a
            href="https://www.iwhver.com/?s=%E4%BA%8C%E7%AD%BE"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            官网
          </a>
          查看
        </Typography>
      </div>
    ),
  },
  {
    selector: '[data-tour="speciala"]',
    content: "南回归线以南，可以从事农牧业集二签区域",
  },
  {
    selector: '[data-tour="specialb"]',
    content: "受山火影响，可以从事建筑业集二签的区域",
  },
  {
    selector: '[data-tour="harvestmap"]',
    content:
      "主要二签区域用工需求月份分布，可以点击相应区域查看该区域不同月份各工种的用工需求",
  },
  {
    selector: '[data-tour="farmlist"]',
    content: "农场详细分布，可以点击农场图标查看该农场详情和联系方式",
  },
  {
    selector: '[data-tour="iwhver"]',
    content: () => (
      <div>
        圈子内容：包括了工作、打工度假圈子、市集、活动等信息
        <br />
        可以分月份筛选
        <br />
        默认展示最近七天发布的信息
      </div>
    ),
  },
  {
    selector: '[data-tour="postinfo"]',
    content: () => (
      <div>
        点击该按钮，可以在地图上放置location图标，可以拖动图标改变位置
        <br />
        然后可以在弹出页面发布朋友圈/工作/市集/农场信息，信息内容会被安置在所选地点{" "}
        <br />
        发布信息需要登陆
      </div>
    ),
  },
  {
    selector: '[data-tour="menu"]',
    content: "目录按钮，登陆后可以点击切换到列表模式/个人列表页面",
  },
  {
    selector: "main",
    content: () => (
      <div>
        如果使用的时候有什么疑问，或者有什么反馈，可以登陆后点击右上角的反馈图标，反馈给我们
        <br />
        也希望大家能多多分享工作/农场等相关的信息，前人栽树后人乘凉，多多建设我们的打工度假圈儿
      </div>
    ),
  },
]

const ensteps = [
  {
    selector: '[data-tour="toolbar"]',
    content: "Function bar, can choose to show/hide layers on map via tap/click",
  },
  {
    selector: '[data-tour="satellite"]',
    content: "Show/hide satellite layer, can click to have a look",
  },
  {
    selector: '[data-tour="specialnorth"]',
    content: () => (
      <div>
        <p>Specified areas of northern Australia: </p>
        <ul>
          <li>Plant and animal cultivation</li>
          <li>Fishing and pearling</li>
          <li>Tree farming and felling</li>
          <li>Tourism and hospitality</li>
          <li>Construction</li>
        </ul>
        <Typography>
          More info, click 
          <a
            href="https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/work-holiday-462/second-work-holiday-462"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            Official link
          </a>
          to have a look, or go to our website 
          <a
            href="https://www.iwhver.com/?s=%E4%BA%8C%E7%AD%BE"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#007bff", textDecoration: "underline" }}
          >
            iwhver.com
          </a>
          to have a look
        </Typography>
      </div>
    ),
  },
  {
    selector: '[data-tour="speciala"]',
    content: "Specified areas of regional Australia",
  },
  {
    selector: '[data-tour="specialb"]',
    content: "Bushfire affected areas, can get second year visa by work in construction industry",
  },
  {
    selector: '[data-tour="harvestmap"]',
    content:
      "Harvest map for main farm area, so you can click the area to check the demand for workforce in differen months.",
  },
  {
    selector: '[data-tour="farmlist"]',
    content: "Farm list layer, you can click the icon to check the farm details",
  },
  {
    selector: '[data-tour="iwhver"]',
    content: () => (
      <div>
        Here include：markets, works, posts, and events information.
        <br />
        you can filter them by month
        <br />
        default to show recent 7 days information
      </div>
    ),
  },
  {
    selector: '[data-tour="postinfo"]',
    content: () => (
      <div>
        Click this button, you can place a 'location' icon on the map, and you can dray and move the point
        <br />
        Then you can post market/works/farm/your thoughts on the right sider bar, and the information will be marked on the map point you selected
        <br />
        You will need to login to post
      </div>
    ),
  },
  {
    selector: '[data-tour="menu"]',
    content: "Menu button, can click to switch to List Mode/Personal List",
  },
  {
    selector: "main",
    content: () => (
      <div>
        If you have any questsion, welcome to click the feedback icon on top right after you logged in.
        <br />
        And we hope you guys can help each other by sharing this platform as well as works/farms information here, to make our community better.
      </div>
    ),
  },
]

const TourCom = (props) => {
  const dispatch = useDispatch()
  const showTour = useSelector((state) => state.auth.user.showTour)
  const en = useSelector(state => state.auth.user.initEn)
  const handleClose = () => {
    dispatch({ type: "USER_PROFILE_SHOW_TOUR" })
  }
  return (
    <>
      {showTour ? (
        <Tour steps={en ? ensteps: steps} isOpen={showTour} onRequestClose={handleClose} />
      ) : null}
    </>
  )
}

export default TourCom
