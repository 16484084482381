import { take, fork, cancel, call, put, cancelled, race } from 'redux-saga/effects'

// // Helper for api errors
import { apiPost } from 'lib/api-call-saga'

import { endpoints } from 'lib/end-points'

import jwtDecode from 'jwt-decode'

// Our login constants
import { LOGIN_REQUESTING, LOGIN_SUCCESS, LOGIN_ERROR, LOGIN_OPENID } from './constants'

// So that we can modify our User piece of state
import { userSet, userLogout } from 'views/Auth/Profile/actions'

import { USER_LOGOUT } from 'views/Auth/Profile/constants'

function* logout() {
  // dispatches the USER_LOGOUT action
  yield put(userLogout())
  // remove our token
  localStorage.removeItem('token')
}

function* loginFlow(username, password, openid) {
  let token
  let apiCallParams = { username, password, openid }
  try {
    token = yield call(apiPost, endpoints.obtainTokens, apiCallParams)
    let accessTokenDecoded = jwtDecode(token.access)
    localStorage.setItem('token', JSON.stringify(token))
    yield put(userSet(accessTokenDecoded))
    yield put({ type: LOGIN_SUCCESS })
  } catch (error) {
    // error? send it to redux
    yield put({ type: LOGIN_ERROR, error })
  } finally {
    // No matter what, if our `forked` `task` was cancelled
    // we will then just redirect them to login
    if (yield cancelled()) {
    }
  }

  // return the token for health and wealth
  return token
}

function* openidFlow(token, penid) {

  let accessTokenDecoded = jwtDecode(token.access)
  localStorage.setItem('token', JSON.stringify(token))
  yield put(userSet(accessTokenDecoded))
  yield put({ type: LOGIN_SUCCESS })

  // return the token for health and wealth
  return token
}

// Our watcher (saga).  It will watch for many actions.
function* loginWatcher() {
  while (true) {
    //sit here and WAIT for this LOGIN_REQUESTING action and take USer/pass from the payload.
    const {userlogin, openidlogin} = yield race(
      {
        userlogin: take(LOGIN_REQUESTING),
        openidlogin: take(LOGIN_OPENID)
      }
    )
    if (userlogin) {
      const {email, password, openid} = userlogin
      const task = yield fork(loginFlow, email, password, openid)
      const action = yield take([USER_LOGOUT, LOGIN_ERROR])
      if (action.type === USER_LOGOUT) yield cancel(task)
    }

    if (openidlogin) {
      const {token, openid} = openidlogin
      const task = yield fork(openidFlow, token, openid)
      const action = yield take([USER_LOGOUT, LOGIN_ERROR])
      if (action.type === USER_LOGOUT) yield cancel(task)
    }

    yield call(logout)
  }
}

export default loginWatcher
