import { combineReducers } from "redux"

import user from "views/Auth/Profile/reducer"
import login from "views/Auth/Login/reducer"
import leftNav from "./components/SideNavLeft/reducer"
import rightNav from "./components/SideNavRight/reducer"

const appReducer = combineReducers({
  auth: combineReducers({
    login,
    user
  }),
  ui: combineReducers({
    leftNav,
    rightNav,
    // editToggle,
    // alerts: combineReducers({
    //   flash: flashMessage
    // })
  })
})

export default appReducer
