import React from "react"
import { Message } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"

import { useDispatch } from "react-redux"
const FeedBackIcon = () => {
  const dispatch = useDispatch()
  const openFeedback = () => {
    dispatch({ type: "USER_SHOW_FEEDBACK_FORM" })
  }
  return (
    <IconButton color="inherit" onClick={openFeedback}>
      <Message fontSize="small" />
    </IconButton>
  )
}

export default FeedBackIcon
